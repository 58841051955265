import { NewDocumentActions } from './action';
import { NewDocumentState } from './state';

export const initialState: NewDocumentState = {
  pagination: {
    currentPage: 1,
    from: 1,
    lastPage: 1,
    perPage: 10,
    to: 1,
    total: 1,
  },
  changesMade: false,
  newDocumentModalOpen: false,
  selectedDocumentModalOpen: {
    isOpen: false,
    type: '',
  },
  selectedDocument: {
    docTypeId: -1,
    templateId: -1,
    templateName: '',
  },
};

export default function newDocumentReducer(state: NewDocumentState = initialState, action: NewDocumentActions) {
  switch (action.type) {
    //pagination
    case '@@Pagination/setNewDocumentPagination':
      return {
        ...state,
        pagination: action.data,
      };
    case '@@Pagination/setNewDocumentCurrentPage':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: action.currentPage,
        },
      };
    case '@@Pagination/resetNewDocumentPagination':
      return {
        ...state,
        ...initialState.pagination,
      };
    case '@@Pagination/setNewDocumentChangesMade':
      return {
        ...state,
        changesMade: action.changesMade,
      };
    //end of pagination
    case '@@ServiceDocument/setNewDocumentModalOpen':
      return {
        ...state,
        newDocumentModalOpen: action.isOpen,
      };
    case '@@ServiceDocument/setSelectedDocumentModalOpen':
      return {
        ...state,
        selectedDocumentModalOpen: {
          isOpen: action.isOpen,
          type: action.documentType ?? '',
        },
      };
    case '@@ServiceDocument/setSelectedDocument':
      return {
        ...state,
        selectedDocument: action.data,
      };
    default:
      return state;
  }
}
