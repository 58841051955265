import axios from "axios";
import Documents from "components/Documents";
import { CloseIcon } from "components/IconsOnly";
import { DynamicsSectionRow, SectionReport, SectionRow } from "components/OrderInfo/OrderSection";
import PreviewNewDocumentModal from "components/PreviewNewDocument";
import "css/OrderByIdPage.css";
import "css/Table.css";
import { formatDate, formatDateAndTime } from "helpers/date";
import { A_ASSIGN_WORKER, midLevelUp } from "models/authorities";
import { FetchAssignedWorkers, OrderById } from "models/responseModels";
import {
  AreaType,
  DIVISIONS,
  DivisionType,
  PROGRESS_STATUS,
  SERVICE_TYPES,
} from "models/variables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Modal, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setIsLoading } from "redux/Loading/action";
import {
  assignWorkerToList,
  clearOrderByIdData,
  setOrderByIdData,
  setServiceReport,
} from "redux/Order/action";
import { setScreenIndicatorText } from "redux/ScreenIndicator/action";
import { IRootState } from "store";

export interface AllWorkersType {
  id: number;
  chiName: string;
  mobile: string;
  staffNumber: string;
  role: string;
  division: DivisionType;
  residenceDistrict: string;
  area: AreaType;
}

function OrderHistoryByIdPage() {
  const [openDynamics, setOpenDynamics] = useState(false);
  const [assignedWorkers, setAssignedWorkers] = useState<FetchAssignedWorkers[]>([]);
  const auth = useSelector((state: IRootState) => state.auth);
  const userRole = auth.role;
  const pathname = useSelector((state: IRootState) => state.router.location.pathname);
  const pathnameSplit = pathname.split("/");
  const orderId = pathnameSplit[pathnameSplit.length - 1];
  const dispatch = useDispatch();

  const orderById = useSelector((state: IRootState) => state.orderById);
  const order = orderById.orderById;

  const saveAcjNo = () => {};
  const handleSubmitReport = () => {};
  const handleSubmitSubject = () => {};

  const fetchOrderById = async () => {
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/orders/${orderId}`);
      const res = await axios.get<OrderById>(url.toString());
      const result = res.data;
      dispatch(setOrderByIdData(result));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  // AssignWorkers
  const fetchAssignedWorkers = async () => {
    dispatch(setIsLoading(true));
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/order-staffs/${orderId}`);
      const res = await axios.get(url.toString());
      const result = res.data;
      setAssignedWorkers(result.data);
      for (const i of result.data) {
        dispatch(assignWorkerToList(i.id));
      }
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
    dispatch(setIsLoading(false));
  };

  //useEffect
  useEffect(() => {
    fetchOrderById();
    if (midLevelUp.includes(auth.role)) {
      fetchAssignedWorkers();
    }
    return () => {
      dispatch(clearOrderByIdData());
    };
  }, [dispatch, auth.role]);

  useEffect(() => {
    if (!order.orderNumber) return;
    dispatch(setScreenIndicatorText(order.orderNumber));
  }, [dispatch, order.orderNumber]);

  useEffect(() => {
    fetchAssignedWorkers();
  }, [dispatch]);

  return (
    <Container
      className="m-0 px-0 full-width flex-column-start relative"
      style={{
        maxHeight: "calc(100vh - 108px)",
        paddingBottom: "10em",
        width: "100vw",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Row className="flex-center w-95" style={{ position: "relative", minHeight: "max-content" }}>
        {midLevelUp.includes(userRole) && (
          <div className="mt-2 full-width flex-center">
            <Button onClick={() => setOpenDynamics(true)}>查看發單資料</Button>
            <Modal isOpen={openDynamics}>
              <Container className="pb-4">
                <div className="pt-2 flex-row-end">
                  <div className="p-2" onClick={() => setOpenDynamics(false)}>
                    <CloseIcon />
                  </div>
                </div>
                <DynamicsSectionRow
                  keyText={"Bill to client"}
                  valueText={order.billToClient ?? "-"}
                />
                <DynamicsSectionRow
                  keyText={"Person in charge"}
                  valueText={order.personInCharge ?? "-"}
                />
                <DynamicsSectionRow
                  keyText={"Customer Code"}
                  valueText={order.customerCode ?? "-"}
                />
                <DynamicsSectionRow
                  keyText={"Quotation No."}
                  valueText={order.salesQuoteNo ?? "-"}
                />
                <DynamicsSectionRow
                  keyText={"Customer PO No."}
                  valueText={order.externalDocumentNo ?? "-"}
                />
                <DynamicsSectionRow
                  keyText={"Contract Amount"}
                  valueText={order.contractAmount?.toString() ?? "-"}
                />
                <h6 className="mt-3 mb-0 full-width flex-center" style={{ color: "#AAA" }}>
                  *如要更改任何資料請通知admin同事*
                </h6>
              </Container>
            </Modal>
          </div>
        )}
        <div className="py-2 my-2 sectionContainer">
          <SectionRow keyText={"工作單號"} valueText={order.orderNumber ?? "-"} />
          <SectionRow keyText={"發單日期"} valueText={formatDateAndTime(order.createdAt)} />
          <SectionRow keyText={"客戶名稱"} valueText={order.clientName ?? "-"} />
          <SectionRow keyText={"工作地點"} valueText={order.clientAddress ?? "-"} />
          <SectionRow
            keyText={"預計開始日期"}
            valueText={order.expectedStartDate ? formatDate(order.expectedStartDate) : "-"}
          />
          <SectionRow
            keyText={"預計完工日期"}
            valueText={order.expectedCompleteDate ? formatDate(order.expectedCompleteDate) : "-"}
          />
          <SectionRow keyText={"聯絡人"} valueText={order.clientContactPerson ?? "-"} />
          <SectionRow keyText={"聯絡電話"} valueText={order.clientTel ?? "-"} />
          <SectionRow keyText={"服務性質"} valueText={SERVICE_TYPES[order.serviceType] ?? "-"} />
        </div>
        <div className="py-2 my-2 sectionContainer">
          <SectionRow
            keyText={"施工日期"}
            valueText={order.startDate ? formatDate(order.startDate) : "-"}
          />
          <SectionRow
            keyText={"完工日期"}
            valueText={order.completeDate ? formatDate(order.completeDate) : "-"}
          />
          <SectionRow
            keyText={"ACJ 號碼 / P.O. 號碼"}
            valueText={order.acjNo ? order.acjNo : ""}
            index={"acjNo"}
            putFunction={saveAcjNo}
          />
          <SectionRow keyText={"進度"} valueText={PROGRESS_STATUS[order.progressStatus] ?? "-"} />
        </div>
        <div className="py-2 my-2 sectionContainer">
          <SectionRow keyText={"消息來源"} valueText={order.informedBy ?? "-"} />
          <SectionRow keyText={"工程部門"} valueText={DIVISIONS[order.division] ?? "-"} />
        </div>
        <div className="py-2 my-2 sectionContainer flex-center">
          <SectionReport
            keyText={"服務事項"}
            valueText={order.serviceSubject ?? "-"}
            editText={setServiceReport}
            isEditMode={false}
            idx={"serviceSubject"}
            onSubmit={handleSubmitSubject}
            readOnly={true}
          />
        </div>
        <div className="py-2 my-2 sectionContainer flex-center">
          <SectionReport
            keyText={"服務報告"}
            valueText={order.serviceReport ?? "-"}
            editText={setServiceReport}
            isEditMode={false}
            idx={"serviceReport"}
            onSubmit={handleSubmitReport}
            readOnly={true}
          />
        </div>
        {A_ASSIGN_WORKER.includes(userRole) && (
          <section className="pt-2 my-2 sectionContainer flex-center">
            <div
              className="my-2 px-2 flex-column-start full-width"
              style={{ alignItems: "flex-start", position: "relative" }}
            >
              <div className="px-2 mx-2 sectionHead flex-row-between">
                <div className="mb-2">{"參與員工"}</div>
              </div>
              <div className="pb-2 full-width flex-column-start" style={{ overflowX: "auto" }}>
                {!assignedWorkers.length ? (
                  <div className="flex-center pt-2">尚未安排</div>
                ) : (
                  assignedWorkers.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="m-1 full-width flex-row-start"
                        style={{ minHeight: "32px" }}
                      >
                        <div className="mx-2 flex-row-start staffNumberContainer">
                          {item.staffNumber || "-"}
                        </div>
                        <div className="mx-2 flex-row-start nameContainer">
                          {item.chiName || "-"}
                        </div>
                        <div className="mx-2 flex-row-start callAndMobile">{item.mobile}</div>
                        <div className="mx-2 flex-row-start roleContainer">{item.role}</div>
                        <div className="mx-2 flex-row-start roleContainer">
                          {"加入時間：" + formatDateAndTime(item.createdAt)}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </section>
        )}
        <Documents readOnly />
        <PreviewNewDocumentModal />
      </Row>
    </Container>
  );
}

export default OrderHistoryByIdPage;
