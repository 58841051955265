import "App.css";
import axios from "axios";
import LoadingModal from "components/LoadingModal";
import Header from "components/OrderInfo/Header";
import SaveSuccessModal from "components/SaveSuccessModal";
import TopHeader from "components/ScreenIndicator";
import ClientPage from "pages/ClientPage";
import { excludeList } from "pages/globalSetting";
import GuestSatisfactoryPage from "pages/GuestSatisfactoryPage";
import HelpPage from "pages/HelpPage";
import ImageUploadPage from "pages/ImageUploadPage";
import LoginPage from "pages/LoginPage";
import OrderByIdPage from "pages/OrderByIdPage";
import OrderHistoryByIdPage from "pages/OrderHistoryByIdPage";
import OrderHistoryGuestSatisfactoryPage from "pages/OrderHistoryGuestSatisfactoryPage";
import OrderHistoryImagePage from "pages/OrderHistoryImagePage";
import OrderHistoryPage from "pages/OrderHistoryPage";
import OrderListPage from "pages/OrderListPage";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { checkLogin } from "redux/Auth/action";
import { IRootState } from "store";

function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: IRootState) => state.auth.isAuthenticated);
  const userId = useSelector((state: IRootState) => state.auth.userId);
  const global = useSelector((state: IRootState) => state.loading);
  const isLoading = global.isLoading;
  const pathname = useSelector((state: IRootState) => state.router.location.pathname);

  useEffect(() => {
    if (!isLoggedIn || !userId) {
      dispatch(checkLogin());
    }
  }, [dispatch, isLoggedIn, userId]);

  axios.defaults.baseURL = `${process.env.REACT_APP_API_PATH}`;
  axios.defaults.headers.get["Content-Type"] = "application/json; charset=utf-8";
  axios.defaults.headers.post["Content-Type"] = "application/json; charset=utf-8";
  axios.defaults.withCredentials = true;

  return (
    <div className="App">
      {
        <div
          style={{
            maxHeight: "100vh",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <div className="pageContainer">
            <Switch>
              <Route exact path="/">
                <Redirect to="/order" />
              </Route>
              <Route exact path="/login" component={LoginPage} />
              {isLoggedIn && (
                <>
                  <TopHeader />
                  {!excludeList.includes(pathname) && <Header />}
                  <Route exact path="/order" component={OrderListPage} />
                  <Route exact path="/order-history" component={OrderHistoryPage} />
                  <Route exact path="/order/:id" component={OrderByIdPage} />
                  <Route exact path="/order-history/:id" component={OrderHistoryByIdPage} />
                  <Route exact path="/order/image-upload/:id" component={ImageUploadPage} />
                  <Route
                    exact
                    path="/order-history/image-upload/:id"
                    component={OrderHistoryImagePage}
                  />
                  <Route
                    exact
                    path="/order/guest-satisfactory/:id"
                    component={GuestSatisfactoryPage}
                  />
                  <Route
                    exact
                    path="/order-history/guest-satisfactory/:id"
                    component={OrderHistoryGuestSatisfactoryPage}
                  />
                  <Route exact path="/help" component={HelpPage} />
                  <Route exact path="/clients" component={ClientPage} />
                </>
              )}
            </Switch>
          </div>
          <SaveSuccessModal />
          <LoadingModal isLoading={isLoading} />
        </div>
      }
    </div>
  );
}

export default App;
