import axios from "axios";
import { StarIcon } from "components/IconsOnly";
import "css/GuestSatisfactory.css";
import { formatDateAndTime } from "helpers/date";
import { IOrderSatisfactory } from "models/responseModels";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAxiosError } from "redux/Auth/action";
import { IRootState } from "store";

interface IImagesRecord {
  header: string;
}

const MAX_CANVAS_WIDTH = 560;
const satisfactoryText = ["極不滿意", "　", "一般", "　", "非常滿意"];

export default function OrderHistoryGuestSatisfactoryPage({ header }: IImagesRecord) {
  const defaultOrderSatisfactory = {
    communication: 0,
    createdAt: "",
    id: 0,
    safety: 0,
    serviceQuality: 0,
    technical: 0,
    updatedAt: "",
  };
  const [orderSatisfactory, setOrderSatisfactory] =
    useState<IOrderSatisfactory>(defaultOrderSatisfactory);
  const [isNew, setIsNew] = useState(false);
  const [signatureData, setSignatureData] = useState("");
  const [canvasWidth, setCanvasWidth] = useState(
    window.innerWidth > 592 ? MAX_CANVAS_WIDTH : window.innerWidth + 32
  );
  const pathname = useSelector((state: IRootState) => state.router.location.pathname);
  const pathnameSplit = pathname.split("/");
  const orderId = pathnameSplit[pathnameSplit.length - 1];

  const dispatch = useDispatch();

  const fetchGuestSatisfactory = async () => {
    try {
      const res = await axios.get(`order-survey/${orderId}`);
      const result = await res.data;
      if (!result.data) {
        setOrderSatisfactory(defaultOrderSatisfactory);
        setIsNew(true);
      } else {
        setOrderSatisfactory(result.data);
        setIsNew(false);
        const res2 = await axios.get(`/order-signature/${orderId}`);
        const result2 = await res2.data;
        setSignatureData(result2.signature);
      }
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  useEffect(() => {
    fetchGuestSatisfactory();
    if (window.innerWidth > MAX_CANVAS_WIDTH + 32) {
      setCanvasWidth(MAX_CANVAS_WIDTH);
    } else {
      setCanvasWidth(window.innerWidth - 32);
    }
    return () => {
      setOrderSatisfactory(defaultOrderSatisfactory);
    };
  }, []);

  useEffect(() => {
    if (!isNew) {
      fetchGuestSatisfactory();
    }
  }, [isNew]);

  return (
    <div
      className="px-4 flex-column-start"
      style={{
        margin: 0,
        minWidth: "100vw",
        maxWidth: "100vw",
        maxHeight: "calc(100vh - 115px)",
        overflowY: "auto",
      }}
    >
      {!isNew ? (
        <div className="pb-4 flex-column-start full-width" style={{ position: "relative" }}>
          <h6 className="mt-4 full-width" style={{ color: "#AAA" }}>{`已評分及簽名`}</h6>
          <StarCard title={"服務質量"} score={orderSatisfactory.serviceQuality} />
          <StarCard title={"技術"} score={orderSatisfactory.technical} />
          <StarCard title={"溝通"} score={orderSatisfactory.communication} />
          <StarCard title={"安全"} score={orderSatisfactory.safety} />
          <div className="mt-4 flex-column-start full-width">
            <h5>客戶簽署</h5>
            <div
              className="flex-center full-width relative"
              style={{
                border: "solid 1px #0005",
                width: canvasWidth,
                minHeight: (canvasWidth * 9) / 16,
              }}
            >
              <div
                className="absolute"
                style={{ background: "white", right: 0, top: 0, padding: "0 16px" }}
              >
                {formatDateAndTime(orderSatisfactory.createdAt)}
              </div>
              <img
                src={signatureData}
                style={{ maxWidth: canvasWidth, height: (canvasWidth * 9) / 16 }}
                alt="Signature"
              />
            </div>
            <div style={{ minHeight: "5em", width: "100vw" }} />
          </div>
        </div>
      ) : (
        <div className="flex-column-start full-width" style={{ position: "relative" }}>
          <h6 className="mt-4 full-width" style={{ color: "#AAA" }}>
            {`請通知前線員工 要求客戶評分及簽名`}
          </h6>
        </div>
      )}
    </div>
  );
}

interface StarCardProps {
  title: string;
  score: number;
}

const StarCard = (props: StarCardProps) => {
  const { title, score } = props;

  return (
    <div
      className="my-1 d-flex full-width justify-content-center"
      style={{ maxWidth: "480px", minHeight: "160px" }}
    >
      <div className="my-2 py-3 flex-column-center starContainer full-width">
        <h5 className="px-2 flex-row-start full-width">{title}</h5>
        <div className="py-2 flex-center w-95">
          {Array(5)
            .fill(0)
            .map((item, idx) => {
              return (
                <div
                  key={idx + 10}
                  className="mx-1 flex-column-center"
                  style={{
                    width: "60px",
                    whiteSpace: "nowrap",
                    lineHeight: "2em",
                    justifyContent: "space-around",
                  }}
                >
                  <StarIcon
                    position={`${title}_${idx}`}
                    offset={Math.max(0, Math.min(1, score - idx))}
                  />
                  {satisfactoryText[idx]}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
