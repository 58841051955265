import { IPagination } from 'models/responseModels';
import { DivisionType } from 'models/variables';
import { DocumentData } from './state';

export const setAllDocumentData = (data: DocumentData[]) => {
  return {
    type: '@@Document/setDocumentDataArray' as const,
    data,
  };
};

export const setDocumentData = (data: {
  description?: string;
  division?: DivisionType | null;
  docCount?: number;
  formNo?: string;
  id?: number;
  name?: string;
  updatedAt?: string;
}) => {
  return {
    type: '@@Document/setDocumentData' as const,
    data,
  };
};

export const resetDocumentData = () => {
  return {
    type: '@@Document/resetDocumentData' as const,
  };
};

export const setChangesMade = (changesMade: boolean) => {
  return {
    type: '@@Document/setChangesMade' as const,
    changesMade,
  };
};

export const setDocumentPagination = (data: IPagination) => {
  return {
    type: '@@Pagination/setDocumentPagination' as const,
    data,
  };
};

export const setDocumentCurrentPage = (currentPage: number) => {
  return {
    type: '@@Pagination/setDocumentCurrentPage' as const,
    currentPage,
  };
};
export const resetDocumentPagination = () => {
  return {
    type: '@@Pagination/resetDocumentPagination' as const,
  };
};

type ActionCreators =
  | typeof setDocumentData
  | typeof resetDocumentData
  | typeof setAllDocumentData
  | typeof setChangesMade
  | typeof setDocumentPagination
  | typeof setDocumentCurrentPage
  | typeof resetDocumentPagination;

export type DocumentActions = ReturnType<ActionCreators>;
