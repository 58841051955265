export const PAGE_OFFSET = '56px';
export const excludeList = [
  '/login',
  '/login/',
  '/order',
  '/order/',
  '/help',
  '/help/',
  '/order-history',
  '/order-history/',
  '/clients',
  '/clients/',
];
