import React from "react";

export interface NavMainProps {
  handleClick: () => void;
}

function MenuButton(props: NavMainProps) {
  const { handleClick } = props;

  return (
    <div
      className="svgContainer"
      onClick={handleClick}
      style={{
        zIndex: 2,
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="menuPointA"
          d="M5.33301 24H26.6663M5.33301 8H26.6663H5.33301ZM5.33301 16H26.6663H5.33301Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default MenuButton;
