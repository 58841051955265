import {
  CameraIconLarge,
  CameraIconLargeActive,
  OrderCompleteIcon,
  OrderCompleteIconActive,
  WorkDetailIcon,
  WorkDetailIconActive,
} from "components/IconsOnly";
import { push } from "connected-react-router";
import "css/Header.css";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab, setPathnameRoot } from "redux/Loading/action";
import { IRootState } from "store";

function Header() {
  const dispatch = useDispatch();
  const pathname = useSelector((state: IRootState) => state.router.location.pathname);
  const pathnameSplit = pathname.substr(1).split("/");
  const orderId = pathnameSplit[pathnameSplit.length - 1];
  const global = useSelector((state: IRootState) => state.loading);
  const activeTab = global.activeTab;

  const routes = [
    {
      link: `/${global.pathnameRoot}/${orderId}`,
      displayText: "工作單資料",
      icon: <WorkDetailIcon />,
      iconActive: <WorkDetailIconActive />,
    },
    {
      link: `/${global.pathnameRoot}/image-upload/${orderId}`,
      displayText: "上傳媒體",
      icon: <CameraIconLarge />,
      iconActive: <CameraIconLargeActive />,
    },
    {
      link: `/${global.pathnameRoot}/guest-satisfactory/${orderId}`,
      displayText: "評分及簽署",
      icon: <OrderCompleteIcon />,
      iconActive: <OrderCompleteIconActive />,
    },
  ];

  const handleSwitch = (idx: number) => {
    dispatch(setActiveTab(idx));
    dispatch(push(routes[idx].link));
  };

  useEffect(() => {
    return () => {
      dispatch(setActiveTab(0));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(setPathnameRoot(pathnameSplit[0]));
  }, [pathname]);

  return (
    <>
      <div
        className="my-0 flex-center full-width headerContainer"
        style={{ zIndex: 9, width: "100vw" }}
      >
        <div className="flex-center" style={{ fontSize: "24px" }}>
          <div className="flex-row-between" style={{ width: "100vw" }}>
            {routes.map((item, idx) => (
              <div
                key={item.displayText}
                className="px-3 flex-center full-height"
                style={{
                  transition: "all 0.3s ease-out",
                  flex: activeTab === idx ? 2 : 1,
                  fontSize: activeTab === idx ? "20px" : 0,
                  height: "var(--headerHeight)",
                  background: activeTab === idx ? "#999" : "#FFF",
                }}
                onClick={() => handleSwitch(idx)}
              >
                {activeTab === idx ? item.iconActive : item.icon}
                {activeTab === idx && (
                  <div
                    style={{
                      fontSize: "max(2.8vmin, 15px)",
                      margin: "4px",
                      width: activeTab === idx ? "100%" : 0,
                      opacity: activeTab === idx ? 1 : 0,
                      transition: "color 0.3s, opacity 0.2s, width: 0.2s",
                      color:
                        activeTab === idx ? "var(--secondaryTextColor)" : "var(--primaryTextColor)",
                    }}
                  >
                    {item.displayText}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
