import { useDispatch, useSelector } from "react-redux";
import { Col } from "reactstrap";
import { setNewOrderData } from "redux/Order/action";
import { IRootState } from "store";
import TextareaAutosize from "react-autosize-textarea";

interface FormFieldsProps {
  keyText: string;
  valueText: string;
  targetKey: string;
  placeholderText?: string;
  isNumber?: boolean;
  useTextArea?: boolean;
}

export const FormFields = (props: FormFieldsProps) => {
  const { useTextArea = false } = props;
  const dispatch = useDispatch();
  const data = useSelector((state: IRootState) => state.orderById.newOrderData);
  return (
    <Col className={`p-2 full-width flex-row-column-start flex-column flex1`}>
      <div className="flex-row-center" style={{ width: "max-content" }}>
        <h6 style={{ whiteSpace: "nowrap" }}>{props.keyText}</h6>
      </div>
      {useTextArea ? (
        <TextareaAutosize
          rows={4}
          className="full-width p-0 px-1 mx-1"
          style={{ borderColor: "#AAA", borderRadius: "4px" }}
          value={props.valueText ?? ""}
          onChange={(e) =>
            dispatch(setNewOrderData({ ...data, [props.targetKey]: e.currentTarget.value }))
          }
        />
      ) : (
        <input
          type={props.isNumber ? "number" : "text"}
          className="inputStyle"
          placeholder={props.placeholderText ?? ""}
          style={{ width: "100%" }}
          value={props.valueText ?? ""}
          onChange={(e) => {
            if (props.isNumber) {
              dispatch(setNewOrderData({ ...data, [props.targetKey]: parseInt(e.target.value) }));
            } else {
              dispatch(setNewOrderData({ ...data, [props.targetKey]: e.target.value }));
            }
          }}
        />
      )}
    </Col>
  );
};
