import axios from "axios";
import modal from "bootstrap/js/dist/modal";
import { A_ASSIGN_WORKER } from "models/authorities";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setChangesMade } from "redux/Loading/action";
import { resetWorkerList } from "redux/Order/action";
import { setTemplateChangesMade } from "redux/Template/action";
import { IRootState } from "store";

interface DeleteModalProps {
  isOpen: boolean;
  deleteTarget: { name: string; id: number };
  setModal: (args0: boolean) => void;
  addItemString: string;
}

function DeleteModal(props: DeleteModalProps) {
  const { isOpen, deleteTarget, setModal, addItemString } = props;
  const dispatch = useDispatch();
  const pathname = useSelector((state: IRootState) => state.router.location.pathname);
  const pathnameSplit = pathname.split("/");
  const userRole = useSelector((state: IRootState) => state.auth.role);

  const handleDelete = async () => {
    if (addItemString === "工程單員工" && A_ASSIGN_WORKER.includes(userRole)) {
      const orderId = pathnameSplit[pathnameSplit.length - 1];
      try {
        await axios.delete(`/order-staffs/${orderId}`, {
          data: {
            staffIds: [deleteTarget.id],
          },
        });
        dispatch(resetWorkerList());
        dispatch(setChangesMade(true));
      } catch (error) {
        dispatch(handleAxiosError(error));
      } finally {
        setModal(!modal);
      }
    }
    if (addItemString === "文件") {
      try {
        await axios.delete(`/doc`, {
          data: {
            docIds: [deleteTarget.id],
          },
        });
        dispatch(setTemplateChangesMade(true));
        dispatch(setChangesMade(true));
      } catch (error) {
        dispatch(handleAxiosError(error));
      } finally {
        setModal(!modal);
      }
    }
  };

  return (
    <Modal isOpen={isOpen}>
      {addItemString === "圖片" ? (
        <>
          <ModalHeader style={{ border: "none" }}>移除{addItemString}</ModalHeader>
          <ModalBody>確定要移除{addItemString}嗎?</ModalBody>
        </>
      ) : (
        <>
          <ModalHeader style={{ border: "none" }}>
            移除{addItemString} - {deleteTarget.name}
          </ModalHeader>
          <ModalBody>
            確定要移除{addItemString} - {deleteTarget.name} 嗎?
          </ModalBody>
        </>
      )}

      <ModalFooter style={{ border: "none" }}>
        <Button color="danger" onClick={handleDelete}>
          確定
        </Button>
        <Button color="secondary" onClick={() => setModal(!modal)}>
          取消
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default DeleteModal;
