export const setIsLoading = (isLoading: boolean) => {
  return {
    type: '@@Loading/setIsLoading' as const,
    isLoading,
  };
};
export const setChangesMade = (changesMade: boolean) => {
  return {
    type: '@@Loading/setChangesMade' as const,
    changesMade,
  };
};
export const setActiveTab = (activeTab: number) => {
  return {
    type: '@@Loading/setActiveTab' as const,
    activeTab,
  };
};
export const setPathnameRoot = (root: string) => {
  return {
    type: '@@Loading/setPathnameRoot' as const,
    root,
  };
};
export const setMessageModal = (data: { isOpen: boolean; content?: string }) => {
  return {
    type: '@@Loading/setMessageModal' as const,
    data,
  };
};

type ActionCreators =
  | typeof setIsLoading
  | typeof setChangesMade
  | typeof setActiveTab
  | typeof setPathnameRoot
  | typeof setMessageModal;

export type LoadingActions = ReturnType<ActionCreators>;
