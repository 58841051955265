import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setAllDocumentData, setDocumentPagination } from "redux/Document/action";
import { IRootState } from "store";
import DocumentList from "./DocumentList";

function NewDocumentModal() {
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalMaxRow, setModalMaxRow] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [sortByDirection, setSortByDirection] = useState<{ sortType: string; isDesc: boolean }>({
    sortType: "updatedAt",
    isDesc: true,
  });
  const dispatch = useDispatch();
  const pagination = useSelector((state: IRootState) => state.documents.pagination);
  const newDocumentModalOpen = useSelector(
    (state: IRootState) => state.newDocument.newDocumentModalOpen
  );

  const fetchAllDocuments = async (pageNumber: number) => {
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/doc-types`);
      if (modalMaxRow) {
        url.searchParams.set("rows", modalMaxRow.toString());
      }
      url.searchParams.set("page", pageNumber.toString());
      if (!!searchText) url.searchParams.set("search", searchText);
      url.searchParams.set("order", `${sortByDirection.sortType}`);
      url.searchParams.set("direction", `${sortByDirection.isDesc ? "desc" : "asc"}`);
      const res = await axios.get(url.toString());
      const result = res.data;
      dispatch(setAllDocumentData(result.data));
      dispatch(setDocumentPagination(result.pagination));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  useEffect(() => {
    fetchAllDocuments(pagination.currentPage);
  }, []);

  useEffect(() => {
    fetchAllDocuments(pagination.currentPage);
  }, [dispatch, pagination.currentPage]);

  const handleSearch = () => {
    fetchAllDocuments(1);
  };
  useEffect(() => {
    fetchAllDocuments(1);
  }, [sortByDirection.sortType, sortByDirection.isDesc]);

  useEffect(() => {
    if (!modalRef.current) return;
    setModalMaxRow(Math.floor(modalRef.current.getBoundingClientRect().height * (3 / 160)) - 1);
  }, [modalRef]);

  return (
    <Modal
      isOpen={newDocumentModalOpen}
      className="d-flex justify-content-center"
      contentClassName="documentSelection"
    >
      <div ref={modalRef} className="p-4 full-height relative">
        <DocumentList
          header=""
          isSelectionMode={true}
          handleSearch={handleSearch}
          searchText={searchText}
          setSearchText={setSearchText}
          sortByDirection={sortByDirection}
          setSortByDirection={setSortByDirection}
        />
      </div>
    </Modal>
  );
}

export default NewDocumentModal;
