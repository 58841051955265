import { SearchIcon } from "components/IconsOnly";
import "css/SearchBar.css";
import React from "react";

interface SearchBarProps {
  searchText: string;
  setSearchText: (args0: string) => void;
  handleSearch: () => void;
}

function SearchBar(props: SearchBarProps) {
  const { searchText, setSearchText, handleSearch } = props;
  return (
    <div className="mt-3 full-width searchBarContainer">
      <div className="flex-center full-width">
        <input
          className="searchBarInput"
          placeholder={"Search"}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
        />
        <div style={{ zIndex: 1, transform: "translateX(-8px)" }} onClick={handleSearch}>
          <SearchIcon />
        </div>
      </div>
    </div>
  );
}

export default SearchBar;
