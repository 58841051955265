import anime from "animejs";
import { push } from "connected-react-router";
import "css/Nav.css";
import logo from "media/logo.jpg";
import { excludeList } from "pages/globalSetting";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Modal, Row } from "reactstrap";
import { logout } from "redux/Auth/action";
import { setPathnameRoot } from "redux/Loading/action";
import { setScreenIndicatorText } from "redux/ScreenIndicator/action";
import { IRootState } from "store";
import { BackIcon } from "./IconsOnly";
import MenuButton from "./Nav/MenuButton";

export default function TopHeader() {
  const [isActive, setIsActive] = useState(0);
  const [logoutModalIsOpen, setLogoutModalIsOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const text = useSelector((state: IRootState) => state.screenIndicator.display);
  const pathname = useSelector((state: IRootState) => state.router.location.pathname);
  const auth = useSelector((state: IRootState) => state.auth);
  const pathnameSplit = pathname.substr(1).split("/");
  const global = useSelector((state: IRootState) => state.loading);

  const dispatch = useDispatch();

  const navigationTabs = [
    { link: "/order", displayText: "主頁" },
    { link: "/help", displayText: "密碼設定" },
    { link: "/clients", displayText: "客戶資料" },
    { link: "/order-history", displayText: "工作單記錄" },
    { link: "logout", displayText: "登出" },
  ];

  const closePathA = "M5.33301 24H26.6663M5.33301 8H26.6663H5.33301ZM5.33301 16H26.6663H5.33301Z";
  const openPathA = "M5.33301 8L26.6663 24M5.33301 24L26.6663 8";

  const handleClick = (idx: number) => {
    if (navigationTabs[idx].link === "/order") {
      dispatch(setPathnameRoot("order"));
    } else if (navigationTabs[idx].link === "/order-history") {
      dispatch(setPathnameRoot("order-history"));
    }
    if (isActive === idx) {
      dispatch(push(`/${pathnameSplit[0]}`));
      dispatch(setScreenIndicatorText(navigationTabs[idx].displayText));
    } else {
      if (navigationTabs[idx].link === "logout") {
        setLogoutModalIsOpen(true);
      } else {
        setIsActive(idx);
        dispatch(push(navigationTabs[idx].link));
        dispatch(setScreenIndicatorText(navigationTabs[idx].displayText));
      }
    }
    setMenuIsOpen(false);
  };

  const handleClose = () => {
    setLogoutModalIsOpen(false);
  };

  useEffect(() => {
    const current = navigationTabs.find((i) => i.link === window.location.pathname);
    if (!current) {
      return;
    } else {
      handleClick(navigationTabs.indexOf(current));
    }
  }, []);

  useEffect(() => {
    const timeline = anime.timeline({
      duration: 600,
      easing: "easeOutExpo",
    });
    timeline.add({
      targets: ".menuPointA",
      d: [{ value: menuIsOpen ? openPathA : closePathA }],
      stroke: [menuIsOpen ? "#444444" : "#EEEEEE"],
    });
  }, [menuIsOpen]);

  useEffect(() => {
    if (window.location.pathname.startsWith("/order")) {
      setIsActive(0);
    }
  }, [window.location.pathname]);

  return (
    <>
      <header className="screenIndicator flex-row-between">
        <section
          className="flex-row-start flex1"
          onClick={() => {
            dispatch(push(`/${global.pathnameRoot}`));
          }}
        >
          {!excludeList.includes(pathname) && <BackIcon />}
        </section>
        <section
          className="textOverflow flex2"
          style={{
            color: "var(--secondaryTextColor)",
            maxWidth: "55%",
          }}
        >
          {text}
        </section>
        <section className="flex-row-end flex1" style={{ zIndex: 2 }}>
          <div
            className="px-4 flex-center"
            style={{ height: "var(--screenIndicatorHeight)" }}
            onClick={() => setMenuIsOpen(!menuIsOpen)}
          >
            <MenuButton handleClick={() => setMenuIsOpen(!menuIsOpen)} />
          </div>
        </section>
        <section
          className="absolute menuContainer"
          style={{ right: menuIsOpen ? "-4.5%" : "-100%" }}
        >
          {/* <section className="absolute menuContainer" style={{ right: menuIsOpen ? "-4.5%" : "-4.5%" }}> */}
          <div className="flex-column-between full-size">
            <section className="full-width">
              <img src={logo} style={{ maxHeight: "64px" }} alt="logo" />
              <ul>
                {navigationTabs.map((item, idx) => (
                  <li
                    key={item.displayText}
                    className="my-2 p-2 listItem"
                    onClick={() => handleClick(idx)}
                    style={{
                      background:
                        isActive === idx ? "linear-gradient(to bottom, #333, #444e, #333)" : "#FFF",
                      color:
                        isActive === idx ? "var(--secondaryTextColor)" : "var(--primaryTextColor)",
                    }}
                  >
                    {item.displayText}
                  </li>
                ))}
              </ul>
            </section>
            <section className="my-4 full-width">
              <div className="flex-column-center">
                <h5>帳戶資訊</h5>
                <div className="d-flex full-width">
                  <div className="mx-3 flex1 flex-row-end">員工編號:</div>
                  <div className="flex1 flex-row-start">{auth.staffNumber}</div>
                </div>
                <div className="d-flex full-width">
                  <div className="mx-3 flex1 flex-row-end">中文姓名:</div>
                  <div className="flex1 flex-row-start">{auth.chiName}</div>
                </div>
              </div>
              <div style={{ minHeight: "6em" }} />
            </section>
          </div>
        </section>
        {menuIsOpen && (
          <div className="absolute clickElseWhere" onClick={() => setMenuIsOpen(false)} />
        )}
        <LogoutModal isOpen={logoutModalIsOpen} closeAction={handleClose} />
      </header>
      {/* <div className="full=width" style={{ height: "var(--screenIndicatorHeight)" }} /> */}
    </>
  );
}

interface LogoutModalProps {
  isOpen: boolean;
  closeAction: () => void;
}

const LogoutModal = (props: LogoutModalProps) => {
  const dispatch = useDispatch();

  return (
    <Modal centered isOpen={props.isOpen}>
      <Container className="p-4">
        <Row className="mb-4 mt-2 flex-center">
          <Col className="flex-center">
            <h5>確定要登出？</h5>
          </Col>
        </Row>
        <Row className="flex-row-around">
          <Button className="w-40" onClick={props.closeAction}>
            取消
          </Button>
          <Button color="danger" className="w-40" onClick={() => dispatch(logout())}>
            確定
          </Button>
        </Row>
      </Container>
    </Modal>
  );
};
