import axios from 'axios';
import { push } from 'connected-react-router';
import { RoleType } from 'models/variables';
import { ILoginData } from 'pages/LoginPage';
import { Dispatch } from 'react';
import { setMessageModal } from 'redux/Loading/action';
import { history, ThunkDispatch } from 'store';

export const logUserIn = (role: string, userId: string) => {
  return {
    type: 'LOGIN_SUCCESS' as const,
    role,
    userId,
  };
};

export const setUserData = (data: {
  chiName?: string;
  email?: string;
  mobile?: string;
  staffNumber?: string;
  role?: string;
}) => {
  return {
    type: 'LOGIN_DATA' as const,
    data,
  };
};

export const fetchFailed = (error: string, key: string) => {
  return {
    type: 'LOGIN_FAILED' as const,
    error,
    key,
  };
};

const logUserOut = () => {
  return {
    type: 'LOGOUT_SUCCESS' as const,
  };
};

type ActionCreators =
  | typeof logUserIn
  | typeof fetchFailed
  | typeof logUserOut
  | typeof setUserData;

export type IAuthActions = ReturnType<ActionCreators>;

export function login(data: ILoginData) {
  return async (dispatch: ThunkDispatch) => {
    try {
      const result = await axios.post<{
        userEmail: string;
        userMobile: string;
        chiName: string;
        staffNumber: string;
        role: string;
        id: number;
      }>(`${process.env.REACT_APP_API_PATH}/login`, data);
      const res = result.data;

      // set data to local storage
      localStorage.setItem('role', res.role);
      localStorage.setItem('userId', String(res.id));

      dispatch(logUserIn(res.role, String(res.id)));
      dispatch(push('/order'));
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: '使用者或密碼不正確' }));
      // dispatch(fetchFailed('LOGIN_FAILED', ''));
    }
  };
}

export function checkLogin() {
  return async (dispatch: ThunkDispatch) => {
    try {
      const res = await axios.get<{
        id: number;
        email: string;
        mobile: string;
        chiName: string;
        staffNumber: string;
        role: RoleType;
      }>('/login/current-user');
      const data = res.data;
      dispatch(logUserIn(data.role, String(data.id)));
      dispatch(
        setUserData({
          chiName: data.chiName,
          email: data.email,
          mobile: data.mobile,
          role: data.role,
          staffNumber: data.staffNumber,
        })
      );
    } catch (e) {
      dispatch(logUserOut());
      dispatch(push('/login'));
    }
  };
}

export function logout() {
  return async (dispatch: Dispatch<IAuthActions>) => {
    await axios.get('/login/logout');
    localStorage.removeItem('role');
    localStorage.removeItem('userId');
    dispatch(logUserOut());
    history.push('/login');
  };
}

// handle error
export const handleFetchErrors = (status: number, message: string) => {
  return async (dispatch: ThunkDispatch) => {
    if (status === 401) {
      if (message === 'Invalid token.' || 'No token.') {
        dispatch(setMessageModal({ isOpen: true, content: '請先登入！' }));
        dispatch(logout());
      }
      if (message === 'Unauthorized access.') {
        dispatch(setMessageModal({ isOpen: true, content: '無此權限！請聯絡Admin同事。' }));
      }
    } else if (status === 413) {
      dispatch(setMessageModal({ isOpen: true, content: '檔案太大！請重新選擇。' }));
    } else {
      console.error(message);
    }
  };
};

export const handleAxiosError = (error: any) => {
  return async (dispatch: ThunkDispatch) => {
    if (error.response) {
      dispatch(handleFetchErrors(error.response.status, error.response.data.message));
    } else if (error.request) {
      console.error(error.request);
    } else {
      console.error(error);
    }
  };
};
