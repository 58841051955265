import React from "react";
import { Modal, Row, Col, Spinner } from "reactstrap";

interface LoadingModalProps {
  isLoading: boolean;
}
function LoadingModal(props: LoadingModalProps) {
  return (
    <Modal centered isOpen={props.isLoading}>
      <Row className="my-4 flex-center">
        <Col className="flex-center">
          <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
          <h4 className="mx-4">Loading</h4>
        </Col>
      </Row>
    </Modal>
  );
}

export default LoadingModal;
