import { IAuthActions } from './action';
import { IAuthState } from './state';

export const initialState: IAuthState = {
  isAuthenticated: false,
  role: '',
  userId: '',
  msg: '',
  chiName: '',
  email: '',
  mobile: '',
  staffNumber: '',
};

export default function authReducer(state: IAuthState = initialState, action: IAuthActions) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isAuthenticated: true,
        role: action.role,
        userId: action.userId,
        msg: '',
      };
    case 'LOGIN_DATA':
      return {
        ...state,
        role: action.data.role ?? state.role,
        chiName: action.data.chiName ?? state.chiName,
        email: action.data.email ?? state.email,
        mobile: action.data.mobile ?? state.mobile,
        staffNumber: action.data.staffNumber ?? state.staffNumber,
      };
    case 'LOGIN_FAILED':
      return {
        ...state,
        msg: action.error,
      };
    case 'LOGOUT_SUCCESS':
      return {
        ...initialState,
        isAuthenticated: false,
      };
    default:
      return state;
  }
}
