import { IPagination, ITemplateById, ITemplates } from 'models/responseModels';
import { DivisionType } from 'models/variables';

export const setAllTemplatesArray = (data: ITemplates[]) => {
  return {
    type: '@@Templates/setAllTemplatesArray' as const,
    data,
  };
};

export const setTemplateData = (data: {
  id?: number;
  division?: DivisionType | null;
  docTypeDescription?: string;
  docTypeId?: number;
  docTypeIsActive?: boolean;
  docTypeName?: string;
  formNo?: string;
  isTemplate?: boolean;
  name?: string;
  updatedAt?: string;
  createdAt?: string;
}) => {
  return {
    type: '@@Templates/setTemplateData' as const,
    data,
  };
};
export const setTemplateContentArray = (data: ITemplateById[]) => {
  return {
    type: '@@Templates/setTemplateContentArray' as const,
    data,
  };
};

export const resetTemplateData = () => {
  return {
    type: '@@Templates/resetTemplateData' as const,
  };
};

export const setTemplateChangesMade = (changesMade: boolean) => {
  return {
    type: '@@Templates/setTemplateChangesMade' as const,
    changesMade,
  };
};

export const setTemplatePagination = (data: IPagination) => {
  return {
    type: '@@Pagination/setTemplatePagination' as const,
    data,
  };
};

export const setTemplateCurrentPage = (currentPage: number) => {
  return {
    type: '@@Pagination/setTemplateCurrentPage' as const,
    currentPage,
  };
};
export const resetTemplatePagination = () => {
  return {
    type: '@@Pagination/resetTemplatePagination' as const,
  };
};

type ActionCreators =
  | typeof setAllTemplatesArray
  | typeof setTemplateData
  | typeof resetTemplateData
  | typeof setTemplateChangesMade
  | typeof setTemplatePagination
  | typeof setTemplateCurrentPage
  | typeof resetTemplatePagination
  | typeof setTemplateContentArray;

export type TemplateActions = ReturnType<ActionCreators>;
