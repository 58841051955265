export const DISTRICTS = Object.freeze({
  ISLANDS: '離島區' as const,
  KWAI_TSING: '葵青區' as const,
  NORTH: '北區' as const,
  SAI_KUNG: '西貢區' as const,
  SHA_TIN: '沙田區' as const,
  TAI_PO: '大埔區' as const,
  TSUEN_WAN: '荃灣區' as const,
  TUEN_MUN: '屯門區' as const,
  YUEN_LONG: '元朗區' as const,
  KOWLOON_CITY: '九龍城區' as const,
  KWUN_TONG: '觀塘區' as const,
  SHAM_SHUI_PO: '深水埗區' as const,
  WONG_TAI_SIN: '黃大仙區' as const,
  YAU_TSIM_MONG: '油尖旺區' as const,
  CENTRAL_AND_WESTERN: '中西區' as const,
  EASTERN: '東區' as const,
  SOUTHERN: '南區' as const,
  WAN_CHAI: '灣仔區' as const,
  AIRPORT: '機場區' as const,
  LANTAU_ISLAND: '大嶼山區' as const,
  TSEUNG_KWAN_O: '將軍澳區' as const,
  KOWLOON_TONG: '九龍塘區' as const,
});

export const DISTRICTS_MAPPING = Object.entries(DISTRICTS).reduce(
  (acc, cur) => acc.set(cur[1], cur[0]),
  new Map()
);

export type DistrictType = keyof typeof DISTRICTS;
