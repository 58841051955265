// import DocumentById from "pages/Admin/DocumentById";
// import TemplateById from "pages/Admin/TemplateById";
import React from "react";
import { useSelector } from "react-redux";
import { Modal } from "reactstrap";
import { IRootState } from "store";
import DocumentById from "./DocumentById";
import TemplateById from "./TemplateById";

function PreviewNewDocumentModal() {
  const selectedDocumentModalOpen = useSelector(
    (state: IRootState) => state.newDocument.selectedDocumentModalOpen
  );
  return (
    <Modal size="xl" isOpen={selectedDocumentModalOpen.isOpen}>
      {selectedDocumentModalOpen.type === "template" && <TemplateById isSelectionMode={true} />}
      {selectedDocumentModalOpen.type === "docType" && <DocumentById isSelectionMode={true} />}
    </Modal>
  );
}

export default PreviewNewDocumentModal;
