import axios from "axios";
import { push } from "connected-react-router";
import "css/InfoCard.css";
import { getDateFormat } from "helpers/date";
import { IOrders, OrderById } from "models/responseModels";
import { enableUrgentIcon } from "models/urgentIcon";
import { progressColor, PROGRESS_STATUS, SERVICE_TYPES } from "models/variables";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAxiosError } from "redux/Auth/action";
import { setOrderByIdData } from "redux/Order/action";
import { IRootState } from "store";
import { VeryUrgentIcon } from "./IconsOnly";
// import { NotificationCards } from "models/mobileResponseModels";

interface InfoCardProps {
  item: IOrders;
  onClick?: () => void;
}

function InfoCard({ item, onClick }: InfoCardProps) {
  const dispatch = useDispatch();
  const global = useSelector((state: IRootState) => state.loading);
  const orderById = useSelector((state: IRootState) => state.orderById);
  const order = orderById.orderById;

  const fetchOrderById = async (orderId: number) => {
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/orders/${orderId}`);
      const res = await axios.get<OrderById>(url.toString());
      const result = res.data;
      dispatch(setOrderByIdData(result));
      fetchGuestSatisfactory(orderId);
      dispatch(push(`/${global.pathnameRoot}/${item.id}`));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  const fetchGuestSatisfactory = async (orderId: number) => {
    try {
      const res = await axios.get(`order-survey/${orderId}`);
      const result = await res.data;
      dispatch(setOrderByIdData({ ...order, surveyCompleted: !!result.data }));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  return (
    <div
      key={item.id}
      className="infoCard flex-center"
      style={{ position: "relative" }}
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          fetchOrderById(item.id);
        }
      }}
    >
      <div className="flex-row-start" style={{ padding: "8px", width: "100%" }}>
        <section className="flex-center" style={{ width: "15%" }}>
          <div
            className="flex-center serviceNatureCircle relative"
            style={{ background: progressColor(item.progressStatus), color: "#888" }}
          >
            {item.division === "MAINTENANCE" ? "維" : "工"}
            {String(SERVICE_TYPES[item.serviceType]).match("e-call") &&
              enableUrgentIcon.includes(item.progressStatus) && (
                <div className="absolute" style={{ top: "-25%", right: "-25%" }}>
                  <VeryUrgentIcon />
                </div>
              )}
          </div>
        </section>
        <section className="flex-column-start informationSection">
          <div
            className="flex-row-start full-width"
            style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
          >
            <div style={{ minWidth: "max-content" }}>{"工作單號:"}</div>
            <div className="cardText">{item.orderNumber}</div>
          </div>
          <div className="flex-row-start full-width">
            <div style={{ minWidth: "max-content" }}>{"地點:"} </div>
            <div className="cardText">{item.clientName}</div>
          </div>
          <div className="flex-row-start full-width">
            <div style={{ minWidth: "max-content" }}>{"進度:"} </div>
            <div className="cardText">{PROGRESS_STATUS[item.progressStatus]}</div>
          </div>
        </section>
        <section className="flex-center" style={{ width: "23%" }}>
          <div className="flex-column-start" style={{ margin: "4px" }}>
            <div className="flex-row-start">
              <div>{getDateFormat(new Date(item.createdAt).toLocaleDateString("zh-HK"))}</div>
            </div>
            <div className="flex-row-start">
              <div style={{ minWidth: "max-content" }}>
                {new Date(item.createdAt).toLocaleTimeString("zh-HK", {
                  hour: "numeric",
                  minute: "2-digit",
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default InfoCard;
