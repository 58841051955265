export const pageStyle = /*css*/ `
    
    @media print{
      #amendButton {
        display: none;
      }
      #eachPage {            
        width: 210mm;
        height: 289mm;
        max-width: 210mm;
        max-height: 289mm;
        margin-bottom: 0;
        padding: 0;
        margin: 0;
      }
      #viewcoTable {
        width: 190mm;
        margin: 0;
        margin-left: 360px;
        padding: 0;
        margin-bottom: 80px;
      }

      #viewcoTableOther {
        width: 190mm;
        margin: 0;
        padding: 0;
        margin-bottom: 80px;
      }
      #viewCoHeader {
        display: block;
        width: 100%;
        height: 96px;
      }
      #viewcoContent {
        display: block;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        margin-top: 32px;
        margin-left: 48px;
        max-height: 100px; 
        page-break-inside: auto;
      }
      #viewcoRow {
        line-height: 24px;
        page-break-inside: avoid;
        page-break-before: avoid;
        page-break-after: avoid;
      }
      #viewCoFooter {
        display: block;
        width: 100%;
        // position: fixed;
        // bottom: 8px;
        margin-top: 40px;
      }
      #empty { 
        align-self: flex-end;
        margin-top: 0;
      }
      #textAreaMargin {
        margin-top: 0;
      }
      #fieldNameMargin {
        margin-top: 3px;
      }
      #jobCompletionSignatureFields{
        display: block;
        width: 100%;
        margin-top: 64px;
        margin-bottom: 24px;
      }
    }
  `;
