import axios from "axios";
import { ShowPassword } from "components/IconsOnly";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setMessageModal } from "redux/Loading/action";
import { setScreenIndicatorText } from "redux/ScreenIndicator/action";

export default function HelpPage() {
  const [inputs, setInputs] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const setOldPassword = (text: string) => {
    setInputs({ ...inputs, oldPassword: text });
  };
  const setNewPassword = (text: string) => {
    setInputs({ ...inputs, newPassword: text });
  };
  const setConfirmNewPassword = (text: string) => {
    setInputs({ ...inputs, confirmNewPassword: text });
  };

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    if (inputs.newPassword !== inputs.confirmNewPassword) {
      dispatch(setMessageModal({ isOpen: true, content: "您所輸入的密碼不相同" }));
    } else {
      try {
        await axios.put(`/account/password`, {
          oldPassword: inputs.oldPassword,
          newPassword: inputs.confirmNewPassword,
        });
        dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
        setInputs({ oldPassword: "", newPassword: "", confirmNewPassword: "" });
      } catch (error) {
        dispatch(handleAxiosError(error));
        dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
        setInputs({ oldPassword: "", newPassword: "", confirmNewPassword: "" });
      }
    }
  };

  useEffect(() => {
    dispatch(setScreenIndicatorText("密碼設定"));
    return () => {
      setInputs({ oldPassword: "", newPassword: "", confirmNewPassword: "" });
    };
  }, [dispatch]);

  const disabled = Object.values(inputs).some((i) => !i);

  return (
    <section className="my-2 flex-column-start full-width">
      <form className="full-width flex-column-start">
        <InputRow
          keyValue={"舊密碼"}
          inputText={inputs.oldPassword}
          setInputText={setOldPassword}
        />
        <InputRow
          keyValue={"新密碼"}
          inputText={inputs.newPassword}
          setInputText={setNewPassword}
        />
        <InputRow
          keyValue={"確認新密碼"}
          inputText={inputs.confirmNewPassword}
          setInputText={setConfirmNewPassword}
        />
      </form>
      <div className="my-4 full-width">
        <Button className="w-40" color="primary" disabled={disabled} onClick={handleSubmit}>
          確定
        </Button>
      </div>
    </section>
  );
}

interface InputRowProps {
  keyValue: string;
  inputText: string;
  setInputText: (args0: string) => void;
}

export const InputRow = (props: InputRowProps) => {
  const { keyValue, inputText, setInputText } = props;
  const [showValue, setShowValue] = useState(false);
  return (
    <div className="my-2">
      <div>{keyValue}</div>
      <div className="flex-center relative">
        <input
          className="inputStyle"
          type={showValue ? "text" : "password"}
          value={inputText}
          onChange={(e) => {
            setInputText(e.target.value);
          }}
        />
        <div
          className="absolute p-1"
          style={{ right: "-40px" }}
          onTouchStart={() => setShowValue(true)}
          onTouchEnd={() => setShowValue(false)}
          onMouseDown={() => setShowValue(true)}
          onMouseUp={() => setShowValue(false)}
        >
          <ShowPassword />
        </div>
      </div>
    </div>
  );
};
