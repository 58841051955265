import axios from "axios";
import { AssignList } from "components/AssignList";
import ClientInformation from "components/ClientInformation";
import { FormFields } from "components/FormFields";
import { CallIcon, CloseIcon, DeleteIcon, DownCaret, SearchIcon } from "components/IconsOnly";
import ReactstrapTabNavLink from "components/ReactstrapTabNavLink";
import "css/Calendar.css";
import { isWithin1Week, setDateToStart } from "helpers/date";
import { midLevel } from "models/authorities";
import { DISTRICTS } from "models/districtModels";
import { FetchAssignedWorkers, IClients } from "models/responseModels";
import {
  DIVISIONS,
  DIVISIONS_MAPPING,
  DivisionType,
  ServiceTypes,
  SERVICE_TYPES,
  SERVICE_TYPES_MAPPING,
} from "models/variables";
import { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Nav,
  Row,
  TabContent,
} from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setIsLoading, setMessageModal } from "redux/Loading/action";
import { clearNewOrderData, resetWorkerList, setNewOrderData } from "redux/Order/action";
import { IRootState } from "store";
import { AllWorkersType } from "./OrderByIdPage";

const MODAL_WIDTH = "w-90";
type DropdownType = "" | "SERVICE_TYPE" | "DIVISION_TYPE";

interface NewOrderProps {
  setClose: () => void;
}

export default function NewOrder(props: NewOrderProps) {
  const [warningMessage, setWarningMessage] = useState("");
  const [activeTab, setActiveTab] = useState("WORKER");
  const [workerLists, setWorkerLists] = useState<{
    supervisorList: AllWorkersType[];
    foremenList: AllWorkersType[];
    workerList: AllWorkersType[];
  }>({ supervisorList: [], foremenList: [], workerList: [] });
  const [allUsers, setAllUsers] = useState<AllWorkersType[]>([]);
  const [assignedWorkers, setAssignedWorkers] = useState<FetchAssignedWorkers[]>([]);
  const [assignUserOpen, setAssignUserOpen] = useState(false);
  const [modalSearchText, setModalSearchText] = useState("");
  const [dropdownType, setDropdownType] = useState<DropdownType>("");
  const [clientOpen, setClientOpen] = useState(false);
  const [openQuotationDetails, setOpenQuotationDetails] = useState(true);
  const userRole = useSelector((state: IRootState) => state.auth.role);
  const data = useSelector((state: IRootState) => state.orderById.newOrderData);
  const isServiceTypeOpen = dropdownType === "SERVICE_TYPE";
  const isDivisionTypeOpen = dropdownType === "DIVISION_TYPE";
  const dispatch = useDispatch();
  const auth = useSelector((state: IRootState) => state.auth);
  const tabs = ["WORKER", "FOREMEN", "SUPERVISOR"];

  function toggle(tab: string) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const fetchAllUsers = async () => {
    dispatch(setIsLoading(true));
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/users`);
      url.searchParams.set("type", "form");
      // url.searchParams.set("orderId", `${orderId}`);
      if (!!modalSearchText) url.searchParams.set("search", modalSearchText);
      const res = await axios.get(url.toString());
      const result = res.data;
      setAllUsers(result.data);
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    if (!data.clientId) return;
    const fetchClientById = async () => {
      try {
        const url = new URL(`${process.env.REACT_APP_API_PATH}/clients`);
        url.searchParams.set("id", String(data.clientId));
        const res = await axios.get<IClients>(url.toString());
        const result = res.data;
        dispatch(
          setNewOrderData({
            ...data,
            name: result.name,
            district: result.district,
            address: result.address,
            contactPerson: result.contactPerson,
            contactNumber: result.tel,
            email: result.email,
          })
        );
        const orderUrl = new URL(`${process.env.REACT_APP_API_PATH}/orders`);
        orderUrl.searchParams.set("clientId", `${data.clientId}`);
        orderUrl.searchParams.set("order", `createdAt`);
        orderUrl.searchParams.set("direction", `desc`);
        orderUrl.searchParams.set("rows", "1");
        const res2 = await axios.get(orderUrl.toString());
        const result2 = res2.data;
        const previousOrder = result2.data[0];
        if (isWithin1Week(previousOrder.createdAt)) {
          setWarningMessage(`此客戶一星期內曾新增工作單 ${previousOrder.orderNumber}`);
        }
      } catch (error) {
        dispatch(handleAxiosError(error));
      }
    };
    fetchClientById();
  }, [data.clientId]);

  const redMark = (identifier: boolean) => {
    return {
      color: "red",
      width: !identifier ? "16px" : "0px",
      transition: "all 0.3s",
      opacity: !identifier ? 1 : 0,
    };
  };

  const handleSelectClient = (id: number) => {
    dispatch(setNewOrderData({ ...data, clientId: id }));
    setClientOpen(false);
  };
  const handleSelectServiceType = (item: ServiceTypes) => {
    dispatch(setNewOrderData({ ...data, serviceType: item }));
    setDropdownType("");
  };
  const handleSelectDivisionType = (item: DivisionType) => {
    dispatch(setNewOrderData({ ...data, division: item }));
    setDropdownType("");
  };

  const handleSubmit = async () => {
    try {
      if (!data.clientId) {
        dispatch(setMessageModal({ isOpen: true, content: "請選擇客戶" }));
        return;
      }
      if (!data.division) {
        dispatch(setMessageModal({ isOpen: true, content: "請選擇部門" }));
        return;
      }
      const res = await axios.post(`/orders`, {
        newOrder: data,
      });
      const newOrderId = await res.data.id;
      let assignedStaff: number[] = [];

      if (midLevel.includes(auth.role) && !data.staffIds?.includes(Number(auth.userId))) {
        assignedStaff = [Number(auth.userId)];
      }
      if (data.staffIds && data.staffIds.length) {
        assignedStaff = [...assignedStaff, ...data.staffIds];
      }
      if (!!assignedStaff.length) {
        await axios.post(`/order-staffs/${newOrderId}`, {
          staffIds: assignedStaff,
        });
      }
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
      dispatch(clearNewOrderData());
      props.setClose();
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };

  const handleSelect = async (item: FetchAssignedWorkers) => {
    if (data.staffIds?.includes(item.id)) {
      const tempArray = assignedWorkers.slice();
      tempArray.splice(tempArray.indexOf(item), 1);
      dispatch(setNewOrderData({ ...data, staffIds: tempArray.map((item) => item.id) }));
      setAssignedWorkers(tempArray);
    } else {
      if (data.staffIds) {
        dispatch(setNewOrderData({ ...data, staffIds: [...data.staffIds, item.id] }));
        setAssignedWorkers([...assignedWorkers, item]);
      }
    }
  };

  useEffect(() => {
    setWorkerLists({
      supervisorList: allUsers.filter((i) => i.role === "SUPERVISOR"),
      foremenList: allUsers.filter((i) => i.role === "FOREMEN"),
      workerList: allUsers.filter((i) => i.role === "WORKER"),
    });
  }, [allUsers, assignedWorkers, assignUserOpen]);

  useEffect(() => {
    fetchAllUsers();
    return () => {
      setAssignedWorkers([]);
      dispatch(clearNewOrderData());
      setWarningMessage("");
    };
  }, [dispatch]);

  return (
    <form className="flex-column-center">
      <h5 className="m-0 my-1">新增工作單</h5>
      <div className={`my-3 flex-row-start`}>
        <div
          className="pointer"
          onClick={() => setOpenQuotationDetails(!openQuotationDetails)}
          style={{
            transform: openQuotationDetails ? "rotate(180deg)" : "rotate(0deg)",
            transition: "all 0.4s",
          }}
        >
          <DownCaret />
        </div>
        <h6 className="m-0 mx-2">報價資料</h6>
      </div>
      <section
        className={MODAL_WIDTH}
        style={{
          height: openQuotationDetails ? "600px" : 0,
          opacity: openQuotationDetails ? 1 : 0,
          transition: "all 0.4s",
        }}
      >
        {openQuotationDetails && (
          <>
            <FormFields
              keyText={"Bill to client:"}
              valueText={data.billToClient ?? ""}
              targetKey={"billToClient"}
              placeholderText={"選填"}
            />
            <FormFields
              keyText={"Person in charge:"}
              valueText={data.personInCharge ?? ""}
              targetKey={"personInCharge"}
              placeholderText={"選填"}
            />
            <FormFields
              keyText={"Customer Code:"}
              valueText={data.customerCode ?? ""}
              targetKey={"customerCode"}
              placeholderText={"選填"}
            />

            <FormFields
              keyText={"Quotation No.:"}
              valueText={data.salesQuoteNo ?? ""}
              targetKey={"salesQuoteNo"}
              placeholderText={"選填"}
            />
            <FormFields
              keyText={"Customer PO No.:"}
              valueText={data.externalDocumentNo ?? ""}
              targetKey={"externalDocumentNo"}
              placeholderText={"選填"}
            />
            <FormFields
              keyText={"Contract Amount:"}
              isNumber
              valueText={data.contractAmount?.toString() ?? ""}
              targetKey={"contractAmount"}
              placeholderText={"選填"}
            />

            <Col className={`p-2 full-width flex-row-column-start flex-column flex1`}>
              <div className="flex-row-center" style={{ width: "max-content" }}>
                <h6 style={{ whiteSpace: "nowrap" }}>{"Expected start date:"}</h6>
              </div>
              <DatePicker
                calendarType={"US"}
                format={"y-MM-dd"}
                minDate={
                  userRole === "SUPER_ADMIN"
                    ? undefined
                    : setDateToStart(new Date(Date.now()).toISOString())
                }
                onChange={(e: Date) => {
                  if (!e) {
                    dispatch(setNewOrderData({ ...data, expectedStartDate: null }));
                  } else {
                    if (
                      !!data.expectedCompleteDate &&
                      setDateToStart(data.expectedCompleteDate).valueOf() <
                        setDateToStart(e.toISOString()).valueOf()
                    ) {
                      dispatch(
                        setNewOrderData({
                          ...data,
                          expectedStartDate: e.toISOString(),
                          expectedCompleteDate: null,
                        })
                      );
                    } else {
                      dispatch(setNewOrderData({ ...data, expectedStartDate: e.toISOString() }));
                    }
                  }
                }}
                value={!data.expectedStartDate ? null : new Date(data.expectedStartDate)}
                className="calendarPicker"
                calendarClassName="calendar"
              />
            </Col>
            <Col className={`p-2 full-width flex-row-column-start flex-column flex1`}>
              <div className="flex-row-center" style={{ width: "max-content" }}>
                <h6 style={{ whiteSpace: "nowrap" }}>{"Expected complete date:"}</h6>
              </div>
              <DatePicker
                calendarType={"US"}
                minDate={
                  !data.expectedStartDate
                    ? userRole === "SUPER_ADMIN"
                      ? undefined
                      : setDateToStart(new Date(Date.now()).toISOString())
                    : new Date(data.expectedStartDate)
                }
                format={"y-MM-dd"}
                onChange={(e: Date) => {
                  if (!e) {
                    dispatch(setNewOrderData({ ...data, expectedCompleteDate: null }));
                  } else {
                    dispatch(setNewOrderData({ ...data, expectedCompleteDate: e.toISOString() }));
                  }
                }}
                value={!data.expectedCompleteDate ? null : new Date(data.expectedCompleteDate)}
                className="calendarPicker"
                calendarClassName="calendar"
              />
            </Col>
          </>
        )}
      </section>
      <section className={MODAL_WIDTH}>
        <div className="full-width flex-center">
          <div className="flex-center">
            <div style={redMark(!!data.name)}>{"*"}</div>
            <h6 className="m-0 mx-2">客戶</h6>
          </div>
          <div>
            <Button
              className="m-3"
              onClick={() => {
                setClientOpen(true);
              }}
            >
              更改
            </Button>
          </div>
          <SelectClientModal
            isOpen={clientOpen}
            closeModalAction={setClientOpen}
            handleSelect={handleSelectClient}
          />
        </div>
        <div className="full-width flex-center" style={{ color: "#F00" }}>
          {warningMessage}
        </div>

        <Row
          className={`m-1`}
          style={{
            overflowY: "auto",
            height: !!data.clientId ? "72px" : "0px",
            opacity: !!data.clientId ? 1 : 0,
            transition: "all 0.3s 0.3s",
          }}
        >
          <Col className={`p-2`}>
            <h6>名稱:</h6>
            <div>{!!data.name ? data.name : " - "}</div>
          </Col>
          <Col className={`p-2 align-items-center`}>
            <h6> 區域: </h6>
            <div>{!!data.district ? DISTRICTS[data.district] : " - "}</div>
          </Col>
          <Col className={`p-2`}>
            <h6> 地址: </h6>
            <div>{!!data.address ? data.address : " - "}</div>
          </Col>
        </Row>
        <Row
          className={`m-1 mb-4`}
          style={{
            overflowY: "auto",
            height: !!data.clientId ? "72px" : "0px",
            opacity: !!data.clientId ? 1 : 0,
            transition: "all 0.3s 0.3s",
          }}
        >
          <Col className={`p-2`}>
            <h6> 聯絡人: </h6>
            <div>{!!data.contactPerson ? data.contactPerson : " - "}</div>
          </Col>
          <Col className={`p-2`}>
            <h6> 聯絡電話: </h6>
            <div>{!!data.contactNumber ? data.contactNumber : " - "}</div>
          </Col>
          <Col className={`p-2`}>
            <h6> 電郵地址: </h6>
            <div>{!!data.email ? data.email : " - "}</div>
          </Col>
        </Row>
        <Row className={`my-2 flex-center full-width`}>
          <h6 className="m-0 mx-2 flex-center">行政資料</h6>
        </Row>
        <Row className={`my-3 full-width`}>
          <Col className={`p-2 flex-center flex1`}>
            <div style={redMark(!!data.division)}>{"*"}</div>
            <h6 className="m-0 flex-center">工程部門:</h6>
            <div className="mx-3 d-flex align-items-center">
              <Dropdown
                isOpen={isDivisionTypeOpen}
                toggle={() => {
                  if (isDivisionTypeOpen) {
                    setDropdownType("");
                  } else {
                    setDropdownType("DIVISION_TYPE");
                  }
                }}
              >
                <DropdownToggle className="flex-center">
                  {data.division ? DIVISIONS[data.division] : "-"}
                  <div
                    className="pointer"
                    style={{
                      marginLeft: "16px",
                      transform: isDivisionTypeOpen ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "all 0.4s cubic-bezier(0.9, 1, 0.3, 1.2)",
                    }}
                  >
                    <DownCaret color={"#EEE"} />
                  </div>
                </DropdownToggle>
                <DropdownMenu>
                  {Object.values(DIVISIONS).map((item) => {
                    return (
                      <DropdownItem
                        key={item}
                        md={2}
                        className="d-flex flex-center districtSelectionItem"
                        onClick={() => {
                          handleSelectDivisionType(DIVISIONS_MAPPING.get(item));
                        }}
                      >
                        {item}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>
          </Col>
        </Row>

        <FormFields
          keyText={"ACJ 號碼 / P.O. 號碼:"}
          valueText={data.acjNo ?? ""}
          targetKey={"acjNo"}
          placeholderText={"選填"}
        />
        <FormFields
          keyText={"消息來源:"}
          valueText={data.informedBy ?? ""}
          targetKey={"informedBy"}
          placeholderText={"選填"}
        />

        <Row className={`my-3 full-width flex-column-center`}>
          <Col className={`p-2 flex-center flex1`}>
            <div style={redMark(!!data.serviceType)}>{"*"}</div>
            <h6 className="m-0 flex-center">服務性質:</h6>
            <div className="mx-3 d-flex align-items-center">
              <Dropdown
                isOpen={isServiceTypeOpen}
                toggle={() => {
                  if (isServiceTypeOpen) {
                    setDropdownType("");
                  } else {
                    setDropdownType("SERVICE_TYPE");
                  }
                }}
              >
                <DropdownToggle className="flex-center">
                  {data.serviceType ? SERVICE_TYPES[data.serviceType] : "-"}
                  <div
                    className="pointer"
                    style={{
                      marginLeft: "16px",
                      transform: isServiceTypeOpen ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "all 0.4s cubic-bezier(0.9, 1, 0.3, 1.2)",
                    }}
                  >
                    <DownCaret color={"#EEE"} />
                  </div>
                </DropdownToggle>
                <DropdownMenu>
                  {Object.values(SERVICE_TYPES).map((item) => {
                    return (
                      <DropdownItem
                        key={item}
                        md={2}
                        className="d-flex flex-center districtSelectionItem"
                        onClick={() => {
                          handleSelectServiceType(SERVICE_TYPES_MAPPING.get(item));
                        }}
                      >
                        {item}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>
          </Col>
        </Row>
        <FormFields
          keyText={"服務事項:"}
          valueText={data.serviceSubject ?? ""}
          targetKey={"serviceSubject"}
          placeholderText={""}
          useTextArea
        />
        <FormFields
          keyText={"服務報告:"}
          valueText={data.serviceReport ?? ""}
          targetKey={"serviceReport"}
          placeholderText={""}
          useTextArea
        />
        <section className="pt-2 my-2 flex-center">
          <div
            className="my-2 px-2 flex-column-start full-width"
            style={{ alignItems: "flex-start", position: "relative" }}
          >
            <div className="px-2 mx-2 flex-center full-width">
              <div style={redMark(!!data.staffIds?.length)}>{"*"}</div>
              <h6 className="mb-2">{"參與員工"}</h6>
              <Button
                color="primary"
                className="mb-2 mx-4 px-2 py-1 flex-center full-height"
                style={{ borderRadius: "4px", border: "none" }}
                onClick={() => {
                  setAssignUserOpen(true);
                }}
              >
                管理相關人員
              </Button>
            </div>
            <div className="pb-2 full-width flex-column-start" style={{ overflowX: "auto" }}>
              {!assignedWorkers.length ? (
                <div className="flex-center pt-2">尚未安排</div>
              ) : (
                assignedWorkers.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className="m-1 full-width flex-row-start"
                      style={{ minHeight: "32px" }}
                    >
                      <div className="mx-1 flex-row-around actionContainer pointer">
                        <div
                          className="flex-center tdIconContainer full-height"
                          onClick={() => {
                            handleSelect(item);
                          }}
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                      <div className="mx-2 flex-row-start staffNumberContainer">
                        {item.staffNumber || "-"}
                      </div>
                      <div className="mx-2 flex-row-start nameContainer">{item.chiName || "-"}</div>
                      <div className="mx-2 flex-row-start callAndMobile">
                        <CallIcon />
                        <a
                          className="mx-2"
                          href={`tel:(852)${item.mobile}`}
                          style={{ textDecoration: "none", color: "unset" }}
                        >
                          {item.mobile}
                        </a>
                      </div>
                      <div className="mx-2 flex-row-start roleContainer">{item.role}</div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </section>
        <Row className="my-4 mx-0 full-width flex-column-center">
          <Col className="flex-row-start full-width">
            <h6 style={{ marginBottom: 0, minWidth: "max-content" }}>總收費:</h6>
            <input
              className="inputStyle"
              style={{ width: "100%" }}
              type="number"
              value={data.totalPrice}
              onChange={(e) => {
                if (parseInt(e.target.value) < 0) return;
                dispatch(setNewOrderData({ ...data, totalPrice: parseInt(e.target.value) }));
              }}
            />
          </Col>
          <Col className="my-3 d-flex justify-content-around">
            <Button
              color="primary"
              disabled={
                !data.clientId || !data.division || !data.staffIds?.length || !data.serviceType
              }
              className="m-4"
              style={{ width: "40%" }}
              onClick={handleSubmit}
            >
              儲存
            </Button>
          </Col>
        </Row>
      </section>
      <Modal
        isOpen={assignUserOpen}
        className="d-flex justify-content-center"
        // style={{ minWidth: "70vw", maxHeight: window.innerHeight - 48 + "px", overflowX: "hidden" }}
      >
        <Container className="p-1" style={{ overflowY: "scroll", overflowX: "hidden" }}>
          <div
            className="closeButtonContainer"
            onClick={() => {
              setAssignUserOpen(false);
              dispatch(resetWorkerList());
            }}
          >
            <CloseIcon />
          </div>
          <h5 className="pt-3" style={{ textAlign: "center" }}>{`加入員工`}</h5>
          <Row className="px-2 flex-center my-4 relative">
            <Col className="flex-center full-width relative">
              <input
                className="searchInput"
                value={modalSearchText}
                placeholder={"員工編號 / 中文姓名 / 手機號碼"}
                onChange={(e) => setModalSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") fetchAllUsers();
                }}
              ></input>
              <div className="mx-0" onClick={fetchAllUsers}>
                <SearchIcon />
              </div>
            </Col>
          </Row>
          <Row className="my-3">
            <Col className="flex-center">
              <Button color="primary" onClick={() => setAssignUserOpen(false)} className="w-80">
                確認加入員工
              </Button>
            </Col>
          </Row>
          <div className="flex-column-center full-width">
            <Nav
              tabs
              className="flex-row-around full-width"
              style={{ height: "40px", flexWrap: "nowrap" }}
            >
              {tabs.map((element) => {
                const props = { tabContent: element, activeTab, toggle };
                return <ReactstrapTabNavLink key={element} {...props} />;
              })}
            </Nav>

            <TabContent activeTab={activeTab} className="flex-center full-width">
              <form className="flex-center full-width">
                {
                  {
                    SUPERVISOR: (
                      <AssignList
                        assignList={workerLists.supervisorList}
                        closeAction={setAssignUserOpen}
                        isAssignMode={true}
                        isNewOrderMode
                        assignWorkerToNewOrder={handleSelect}
                      />
                    ),
                    FOREMEN: (
                      <AssignList
                        assignList={workerLists.foremenList}
                        closeAction={setAssignUserOpen}
                        isAssignMode={true}
                        isNewOrderMode
                        assignWorkerToNewOrder={handleSelect}
                      />
                    ),
                    WORKER: (
                      <AssignList
                        assignList={workerLists.workerList}
                        closeAction={setAssignUserOpen}
                        isAssignMode={true}
                        isNewOrderMode
                        assignWorkerToNewOrder={handleSelect}
                      />
                    ),
                  }[activeTab]
                }
              </form>
            </TabContent>
          </div>
        </Container>
      </Modal>
    </form>
  );
}

interface SelectClientModalProps {
  isOpen: boolean;
  closeModalAction: (args0: boolean) => void;
  handleSelect: (args0: number) => void;
}

function SelectClientModal(props: SelectClientModalProps) {
  const { isOpen, closeModalAction, handleSelect } = props;

  return (
    <Modal isOpen={isOpen}>
      <Container className="p-3">
        <ClientInformation
          handleSelect={handleSelect}
          handleClose={() => closeModalAction(false)}
        />
        <Row className="m-2">
          <Button
            onClick={() => {
              closeModalAction(false);
            }}
          >
            關閉
          </Button>
        </Row>
      </Container>
    </Modal>
  );
}
