import { FetchAssignedWorkers } from "models/responseModels";
import { AREAS, AreaType, DIVISIONS, DivisionType } from "models/variables";
import { AllWorkersType } from "pages/OrderByIdPage";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Spinner } from "reactstrap";
import { assignWorkerToList, removeWorkerFromList } from "redux/Order/action";
import { IRootState } from "store";
import { DownCaret } from "./IconsOnly";

export interface ResUsers {
  chiName: string;
  division: DivisionType;
  id: number;
  mobile: string;
  role: string;
  staffNumber: string;
  area: AreaType;
}

interface AssignListProps {
  assignList: FetchAssignedWorkers[] | AllWorkersType[];
  closeAction: (args0: boolean) => void;
  isEditMode?: boolean;
  isAssignMode?: boolean;
  isNewOrderMode?: boolean;
  setChanged?: (args0: boolean) => void;
  assignWorkerToNewOrder?: (args0: FetchAssignedWorkers) => void;
  sortFunction?: (args0: boolean, args1: string) => void;
}

export const AssignList = (props: AssignListProps) => {
  const {
    setChanged,
    assignList,
    closeAction,
    isEditMode = false,
    isAssignMode = false,
    isNewOrderMode = false,
    assignWorkerToNewOrder,
  } = props;
  const [sortDirection, setSortDirection] = useState({ isDesc: true, orderBy: "" }); //true = desc, false = asc
  const dispatch = useDispatch();
  const isLoading = useSelector((state: IRootState) => state.loading.isLoading);
  const assignWorkers = useSelector((state: IRootState) => state.orderById.assignWorkers);
  const newOrderAssignWorkers = useSelector(
    (state: IRootState) => state.orderById.newOrderData.staffIds
  );

  const handleSelectItem = (item: FetchAssignedWorkers | AllWorkersType) => {
    if (isNewOrderMode && assignWorkerToNewOrder) {
      assignWorkerToNewOrder(item as FetchAssignedWorkers);
      return;
    }
    if (isAssignMode) {
      if (assignWorkers.includes(item.id)) {
        dispatch(removeWorkerFromList(item.id));
      } else {
        dispatch(assignWorkerToList(item.id));
      }
      return;
    }
    if (isEditMode) {
      if (setChanged) {
        setChanged(true);
      }
    }
    closeAction(false);
  };

  const handleSort = (orderBy: string) => {
    if (props.sortFunction) {
      if (orderBy === sortDirection.orderBy) {
        setSortDirection({ ...sortDirection, isDesc: !sortDirection.isDesc });
        props.sortFunction(!sortDirection.isDesc, sortDirection.orderBy);
      } else {
        setSortDirection({ isDesc: true, orderBy });
        props.sortFunction(true, orderBy);
      }
    }
  };

  const assignUserTableHeaders = [
    { displayName: "員工編號", className: "staffNumberContainer", orderBy: "staffNumber" },
    { displayName: "中文姓名", className: "nameContainer", orderBy: "chiName" },
    { displayName: "聯絡電話", className: "mobileContainer", orderBy: "mobile" },
    { displayName: "區域", className: "areaContainer", orderBy: "area" },
    { displayName: "部門", className: "divisionContainer", orderBy: "division" },
  ];

  return (
    <div
      className="py-2 px-1 flex-column-center"
      style={{ width: "100%", overflowX: "auto", alignSelf: "center" }}
    >
      <div className="tableHeaders my-1 py-2 flex-row-start full-width">
        {assignUserTableHeaders.map((item) => {
          return (
            <div className="relative" onClick={() => handleSort(item.orderBy)}>
              <h6 className={`flex-row-start mx-4 ${item.className}`}>{item.displayName}</h6>
              {sortDirection.orderBy === item.orderBy && (
                <div
                  className="absolute"
                  style={{
                    top: -4,
                    right: 0,
                    transform: sortDirection.isDesc ? "rotate(0deg)" : "rotate(180deg)",
                    transition: "all 0.3s",
                  }}
                >
                  <DownCaret />
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="full-width relative">
        {isLoading ? (
          <Row className="my-3 d-flex justify-content-center">
            <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
          </Row>
        ) : !assignList.length ? (
          <div className="flex-center py-2">沒有資料</div>
        ) : (
          assignList.map((item, idx) => {
            const chosen = isNewOrderMode
              ? newOrderAssignWorkers?.includes(item.id)
              : assignWorkers.includes(item.id);
            return (
              <div
                key={item + idx.toString()}
                className={`my-1 py-2 flex-row-start full-width assignWorkerContainer ${
                  chosen && "assigned"
                }`}
                onClick={() => {
                  handleSelectItem(item);
                }}
              >
                <div className={`flex-row-start mx-4 staffNumberContainer ${chosen && "chosen"}`}>
                  {item.staffNumber || " - "}
                </div>
                <div
                  className={`flex-row-start mx-4 nameContainer ${chosen && "chosen"}
                  `}
                >
                  {item.chiName || " - "}
                </div>
                <div className={`flex-row-start mx-4 mobileContainer ${chosen && "chosen"}`}>
                  {item.mobile || " - "}
                </div>
                <div className={`flex-row-start mx-4 areaContainer ${chosen && "chosen"}`}>
                  {AREAS[item.area] || " - "}
                </div>
                <div className={`flex-row-start mx-4 divisionContainer ${chosen && "chosen"}`}>
                  {!!item.division ? DIVISIONS[item.division] : " - "}
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
