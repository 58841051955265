import axios from "axios";
import { CloseIcon } from "components/IconsOnly";
import InfoCard from "components/InfoCard";
import SearchBar from "components/SearchBar";
import { push } from "connected-react-router";
import { IClients, IOrders, IPagination } from "models/responseModels";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Container, Modal, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setIsLoading } from "redux/Loading/action";

function ClientPage() {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [allClients, setAllClients] = useState<Array<IClients>>([]);
  const [fetchedOrders, setFetchedOrders] = useState<Array<IOrders>>([]);
  const [orderModalOpen, setOrderModalOpen] = useState(false);
  const [pagination, setPagination] = useState<IPagination | null>(null);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const fetchAllClients = async (pageNumber: number) => {
    dispatch(setIsLoading(true));
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/clients`);
      url.searchParams.set("rows", "10");
      url.searchParams.set("page", pageNumber.toString());
      if (!!searchText) url.searchParams.set("search", searchText);
      url.searchParams.set("order", "name");
      url.searchParams.set("direction", "asc");
      const res = await axios.get<{ data: any; pagination: IPagination }>(url.toString());
      const result = res.data;
      setAllClients(result.data);
      setPagination(result.pagination);
      // setLastPage(result.pagination.lastPage);
    } catch (error) {
      dispatch(handleAxiosError(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchAllClients(1);
  }, []);

  const handleSearch = () => {
    fetchAllClients(1);
  };

  useEffect(() => {
    if (pagination) {
      fetchAllClients(pagination?.currentPage);
      scrollRef.current?.scrollTo(32 * (pagination.currentPage - 1), 0);
    }
  }, [pagination?.currentPage]);

  const fetchOrderByClientId = async (clientId: number) => {
    dispatch(setIsLoading(true));
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/orders`);
      url.searchParams.set("clientId", clientId.toString());
      const res = await axios.get(url.toString());
      const result = res.data;
      console.log(result.data);
      setFetchedOrders(result.data);
      setOrderModalOpen(true);
    } catch (error) {
      dispatch(handleAxiosError(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  return (
    <div className="full-size relative">
      <div className="flex-center full-width">
        {/* <div className="full-width"> */}
        <SearchBar
          searchText={searchText}
          setSearchText={setSearchText}
          handleSearch={handleSearch}
        />
        {/* </div> */}
      </div>
      <section className="pb-3" style={{ maxHeight: window.innerHeight - 160, overflowY: "auto" }}>
        {allClients.map((item) => {
          return (
            <div
              key={item.id}
              className="full-width flex-column-center infoCard py-1"
              style={{ alignItems: "start", height: "unset", minHeight: "80px" }}
              onClick={() => fetchOrderByClientId(item.id)}
            >
              <InfoRow name={"客戶名稱："} value={item.name} />
              <InfoRow name={"客戶地址："} value={item.address} />
            </div>
          );
        })}
        {!allClients.length ? (
          <></>
        ) : (
          <div className="mt-4 flex-column-center">
            <section className="flex-center" style={{ maxWidth: "88vw" }}>
              <div
                className="mx-2 p-3 flex-center"
                style={{ minWidth: "64px", whiteSpace: "nowrap" }}
              >
                頁數
              </div>
              <div
                ref={scrollRef}
                className="p-2 flex-row-start"
                style={{
                  scrollBehavior: "smooth",
                  overflowX: "auto",
                }}
              >
                {pagination &&
                  Array(pagination.lastPage)
                    .fill(0)
                    .map((item, idx) => {
                      return (
                        <div
                          key={idx}
                          className="mx-1 flex-center"
                          style={{
                            background:
                              pagination.currentPage === idx + 1
                                ? "var(--navBackgroundColor)"
                                : "#FFF",
                            color:
                              pagination.currentPage === idx + 1
                                ? "var(--secondaryTextColor)"
                                : "var(--primaryTextColor)",
                            minWidth: pagination.currentPage === idx + 1 ? "48px" : "24px",
                            height: "24px",
                            borderRadius: "3px",
                            transition: "all 0.3s",
                          }}
                          onClick={() => setPagination({ ...pagination!, currentPage: idx + 1 })}
                        >
                          {idx + 1}
                        </div>
                      );
                    })}
              </div>
            </section>
            {pagination && pagination?.lastPage > 1 && (
              <section className="flex-row-around full-width">
                <div
                  className="py-1 px-3"
                  style={{ border: "solid 1px #555", borderRadius: "3px" }}
                  onClick={() => setPagination({ ...pagination!, currentPage: 1 })}
                >
                  第一頁
                </div>
                <div
                  className="py-1 px-3"
                  style={{ border: "solid 1px #555", borderRadius: "3px" }}
                  onClick={() =>
                    setPagination({ ...pagination!, currentPage: pagination?.lastPage! })
                  }
                >
                  最後一頁
                </div>
              </section>
            )}
          </div>
        )}
        <OrderModal
          isOpen={orderModalOpen}
          fetchedOrder={fetchedOrders}
          clientName={fetchedOrders[0] ? fetchedOrders[0].clientName : ""}
          closeAction={() => setOrderModalOpen(false)}
        />
      </section>
    </div>
  );
}

interface InfoRowProps {
  name: string;
  value: string;
}

export const InfoRow = (props: InfoRowProps) => {
  return (
    <div className="px-4 flex-row-start" style={{ alignItems: "flex-start" }}>
      <div style={{ whiteSpace: "nowrap" }}>{props.name}</div>
      <div style={{ fontWeight: 500, textAlign: "left" }}>{props.value}</div>
    </div>
  );
};

export default ClientPage;

interface OrderModalProps {
  clientName: string;
  fetchedOrder: IOrders[];
  isOpen: boolean;
  closeAction: () => void;
}

export const OrderModal = (props: OrderModalProps) => {
  const dispatch = useDispatch();
  return (
    <Modal isOpen={props.isOpen}>
      <Container>
        <Row className="flex-center my-2 relative">
          <h5 className="flex-center my-2">
            {props.clientName}
            <div className="absolute" style={{ top: 4, right: 16 }} onClick={props.closeAction}>
              <CloseIcon />
            </div>
          </h5>
        </Row>
        <Row>
          {props.fetchedOrder.map((i) => {
            return <InfoCard item={i} onClick={() => dispatch(push(`/order/${i.id}`))} />;
          })}
        </Row>
      </Container>
    </Modal>
  );
};
