import axios from "axios";
import { AddIcon, CloseIcon } from "components/IconsOnly";
import InfoCard from "components/InfoCard";
import SearchBar from "components/SearchBar";
import "css/OrderListPage.css";
import { A_ADD_ORDER } from "models/authorities";
import { IOrders, IPagination } from "models/responseModels";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Modal } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setIsLoading } from "redux/Loading/action";
import { clearNewOrderData } from "redux/Order/action";
import { setScreenIndicatorText } from "redux/ScreenIndicator/action";
import { IRootState } from "store";
import NewOrderPage from "./NewOrderPage";

function OrderListPage() {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [pagination, setPagination] = useState<IPagination | null>(null);
  const [searchText, setSearchText] = useState("");
  const [fetchedOrders, setFetchedOrders] = useState<Array<IOrders>>([]);
  const dispatch = useDispatch();
  const [newOrderOpen, setNewOrderOpen] = useState(false);
  const userRole = useSelector((state: IRootState) => state.auth.role);

  const fetchAllOrders = async (pageNumber: number) => {
    dispatch(setIsLoading(true));
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/orders`);
      url.searchParams.set("rows", "10");
      if (userRole !== "SUPER_ADMIN") {
        url.searchParams.set("assigned", "1");
      }
      url.searchParams.set("page", pageNumber.toString());
      if (!!searchText) url.searchParams.set("search", searchText);
      const res = await axios.get(url.toString());
      const result = res.data;
      setFetchedOrders(result.data);
      setPagination(result.pagination);
    } catch (error) {
      dispatch(handleAxiosError(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleSearch = () => {
    fetchAllOrders(1);
  };

  useEffect(() => {
    fetchAllOrders(1);
    dispatch(setScreenIndicatorText("主頁"));
  }, [dispatch]);

  useEffect(() => {
    if (pagination) {
      fetchAllOrders(pagination?.currentPage);
      scrollRef.current?.scrollTo(32 * (pagination.currentPage - 1), 0);
    }
  }, [pagination?.currentPage]);

  useEffect(() => {
    if (!newOrderOpen) {
      fetchAllOrders(1);
    }
  }, [newOrderOpen]);

  return (
    <div
      className="full-width"
      style={{
        height: "100vh",
        overflowY: "auto",
        paddingBottom: "10em",
      }}
    >
      {A_ADD_ORDER.includes(userRole) && (
        <div className="flex-center p-2 full-width">
          <div className="flex-center pointer" onClick={() => setNewOrderOpen(true)}>
            <AddIcon />
            <div className="m-0 mx-2">新增工作單</div>
          </div>
        </div>
      )}
      <SearchBar
        searchText={searchText}
        setSearchText={setSearchText}
        handleSearch={handleSearch}
      />
      <div className="flex-column-start relative" style={{ justifyContent: "flex-start", top: 0 }}>
        {!fetchedOrders.length ? (
          <h6 className="m-4" style={{ color: "#AAA" }}>
            無工作單
          </h6>
        ) : (
          fetchedOrders.map((item) => <InfoCard key={item.id} item={item} />)
        )}
      </div>
      {!fetchedOrders.length ? (
        <></>
      ) : (
        <div className="mt-4 flex-column-center">
          <section className="flex-center" style={{ maxWidth: "88vw" }}>
            <div
              className="mx-2 p-3 flex-center"
              style={{ minWidth: "64px", whiteSpace: "nowrap" }}
            >
              頁數
            </div>
            <div
              ref={scrollRef}
              className="p-2 flex-row-start"
              style={{
                scrollBehavior: "smooth",
                overflowX: "auto",
              }}
            >
              {pagination &&
                Array(pagination.lastPage)
                  .fill(0)
                  .map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        className="mx-1 flex-center"
                        style={{
                          background:
                            pagination.currentPage === idx + 1
                              ? "var(--navBackgroundColor)"
                              : "#FFF",
                          color:
                            pagination.currentPage === idx + 1
                              ? "var(--secondaryTextColor)"
                              : "var(--primaryTextColor)",
                          minWidth: pagination.currentPage === idx + 1 ? "48px" : "24px",
                          height: "24px",
                          borderRadius: "3px",
                          transition: "all 0.3s",
                        }}
                        onClick={() => setPagination({ ...pagination!, currentPage: idx + 1 })}
                      >
                        {idx + 1}
                      </div>
                    );
                  })}
            </div>
          </section>
          {pagination && pagination?.lastPage > 1 && (
            <section className="flex-row-around full-width">
              <div
                className="py-1 px-3"
                style={{ border: "solid 1px #555", borderRadius: "3px" }}
                onClick={() => setPagination({ ...pagination!, currentPage: 1 })}
              >
                第一頁
              </div>
              <div
                className="py-1 px-3"
                style={{ border: "solid 1px #555", borderRadius: "3px" }}
                onClick={() =>
                  setPagination({ ...pagination!, currentPage: pagination?.lastPage! })
                }
              >
                最後一頁
              </div>
            </section>
          )}
        </div>
      )}
      <Modal isOpen={newOrderOpen} centered>
        <Container className="p-2" style={{ overflow: "auto", height: "85vh" }}>
          <div
            className="absolute pointer"
            style={{ right: 8 }}
            onClick={() => {
              setNewOrderOpen(false);
              dispatch(clearNewOrderData());
            }}
          >
            <CloseIcon />
          </div>
          <NewOrderPage
            setClose={() => {
              setNewOrderOpen(false);
              dispatch(clearNewOrderData());
            }}
          />
        </Container>
      </Modal>
    </div>
  );
}

export default OrderListPage;
