import axios from "axios";
import "css/Table.css";
import { DISTRICTS } from "models/districtModels";
import { IClients, IPagination } from "models/responseModels";
import { AREAS } from "models/variables";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Spinner } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setIsLoading } from "redux/Loading/action";
import { IRootState } from "store";
import { CloseIcon, SearchIcon } from "./IconsOnly";

interface IClientInformation {
  handleSelect: (args0: number) => void;
  handleClose: () => void;
}

export default function ClientInformation(props: IClientInformation) {
  const [allClients, setAllClients] = useState<Array<IClients>>([]);
  // const [pagination, setPagination] = useState<IPagination | null>(null);
  const [searchText, setSearchText] = useState("");
  const [chosen, setChosen] = useState<number | null>(null);
  const isLoading = useSelector((state: IRootState) => state.loading.isLoading);
  const dispatch = useDispatch();
  // const global = useSelector((state: IRootState) => state.loading);

  const fetchAllClients = async () => {
    dispatch(setIsLoading(true));
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/clients`);
      if (!!searchText) url.searchParams.set("search", searchText);
      url.searchParams.set("rows", "800");
      const res = await axios.get<{ data: any; pagination: IPagination }>(url.toString());
      const result = res.data;
      setAllClients(result.data);
    } catch (error) {
      dispatch(handleAxiosError(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchAllClients();
  }, []);

  const handleChoose = (id: number) => {
    if (id === chosen) {
      setChosen(null);
    } else {
      setChosen(id);
    }
  };
  const handleClose = () => {
    setChosen(null);
    props.handleClose();
  };

  return (
    <div className="p-1 relative" style={{ overflowX: "hidden" }}>
      <div className="closeButtonContainer" onClick={handleClose}>
        <CloseIcon />
      </div>
      <h5 className="pt-3" style={{ textAlign: "center" }}>{`選擇客戶`}</h5>
      <Row className="px-2 flex-center my-4 relative">
        <Col className="flex-center full-width relative">
          <input
            className="searchInput"
            value={searchText}
            placeholder={"客戶名稱 / 聯絡人 / 聯絡電話 / 地址"}
            style={{ width: "95%" }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") fetchAllClients();
            }}
          ></input>
          <div className="mx-0" onClick={fetchAllClients}>
            <SearchIcon />
          </div>
        </Col>
      </Row>
      <Row className="my-3">
        <Col className="flex-center">
          <Button
            disabled={!chosen}
            color="primary"
            onClick={() => {
              props.handleSelect(chosen!);
            }}
            className="w-80"
          >
            確認
          </Button>
        </Col>
      </Row>

      {!allClients.length ? (
        <div className="my-2 flex-center full-width disableText">尚未加入客戶</div>
      ) : (
        <div
          className="py-2 flex-column-center"
          style={{ width: "100%", overflowX: "auto", alignSelf: "center" }}
        >
          <div className="tableHeaders my-1 py-2 flex-row-start full-width">
            <h6 className="flex-row-start mx-2 clientNameContainer">{"客戶名稱"}</h6>
            <h6 className="flex-row-start mx-2 areaContainer">{"區域"}</h6>
            <h6 className="flex-row-start mx-2 areaContainer">{"地區"}</h6>
            <h6 className="flex-row-start mx-2 contactPersonContainer">{"聯絡人"}</h6>
            <h6 className="flex-row-start mx-2 telContainer">{"聯絡電話"}</h6>
            <h6 className="flex-row-start mx-3 addressContainer">{"地址"}</h6>
          </div>
          <div className="full-width relative">
            {isLoading ? (
              <Row className="my-3 d-flex justify-content-center">
                <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
              </Row>
            ) : !allClients.length ? (
              <div className="flex-center py-2">沒有資料</div>
            ) : (
              allClients.map((item, idx) => {
                const isChosen = chosen === item.id;
                return (
                  <div
                    key={item + idx.toString()}
                    className={`my-1 py-2 flex-row-start full-width assignWorkerContainer ${
                      isChosen && "assigned"
                    }`}
                    onClick={() => {
                      handleChoose(item.id);
                    }}
                  >
                    <div
                      className={`flex-row-start mx-2 clientNameContainer ${isChosen && "chosen"}`}
                    >
                      {item.name || " - "}
                    </div>
                    <div
                      className={`flex-row-start mx-2 areaContainer ${isChosen && "chosen"}
                  `}
                    >
                      {AREAS[item.area] || " - "}
                    </div>
                    <div className={`flex-row-start mx-2 areaContainer ${isChosen && "chosen"}`}>
                      {DISTRICTS[item.district] || " - "}
                    </div>
                    <div
                      className={`flex-row-start mx-2 contactPersonContainer ${
                        isChosen && "chosen"
                      }`}
                    >
                      {item.contactPerson || " - "}
                    </div>
                    <div className={`flex-row-start mx-2 telContainer ${isChosen && "chosen"}`}>
                      {item.tel || " - "}
                    </div>
                    <div className={`flex-row-start mx-3 addressContainer ${isChosen && "chosen"}`}>
                      {item.address || " - "}
                    </div>
                  </div>
                );
              })
            )}
          </div>
          {/* {!fetchedOrders.length ? (
            <></>
          ) : (
            <div className="mt-4 flex-column-center">
              <section className="flex-center" style={{ maxWidth: "88vw" }}>
                <div
                  className="mx-2 p-3 flex-center"
                  style={{ minWidth: "64px", whiteSpace: "nowrap" }}
                >
                  頁數
                </div>
                <div
                  ref={scrollRef}
                  className="p-2 flex-row-start"
                  style={{
                    scrollBehavior: "smooth",
                    overflowX: "auto",
                  }}
                >
                  {pagination &&
                    Array(pagination.lastPage)
                      .fill(0)
                      .map((item, idx) => {
                        return (
                          <div
                            key={idx}
                            className="mx-1 flex-center"
                            style={{
                              background:
                                pagination.currentPage === idx + 1
                                  ? "var(--navBackgroundColor)"
                                  : "#FFF",
                              color:
                                pagination.currentPage === idx + 1
                                  ? "var(--secondaryTextColor)"
                                  : "var(--primaryTextColor)",
                              minWidth: pagination.currentPage === idx + 1 ? "48px" : "24px",
                              height: "24px",
                              borderRadius: "3px",
                              transition: "all 0.3s",
                            }}
                            onClick={() => setPagination({ ...pagination!, currentPage: idx + 1 })}
                          >
                            {idx + 1}
                          </div>
                        );
                      })}
                </div>
              </section>
              {pagination && pagination?.lastPage > 1 && (
                <section className="flex-row-around full-width">
                  <div
                    className="py-1 px-3"
                    style={{ border: "solid 1px #555", borderRadius: "3px" }}
                    onClick={() => setPagination({ ...pagination!, currentPage: 1 })}
                  >
                    第一頁
                  </div>
                  <div
                    className="py-1 px-3"
                    style={{ border: "solid 1px #555", borderRadius: "3px" }}
                    onClick={() =>
                      setPagination({ ...pagination!, currentPage: pagination?.lastPage! })
                    }
                  >
                    最後一頁
                  </div>
                </section>
              )}
            </div>
          )} */}
        </div>
        // <div className="flex-center full-width">
        //   <div className="p-2 viewCoTable">
        //     <SortableTableHeaders
        //       headers={sortableHeaders}
        //       sortControl={handleControlSort}
        //       currentSorting={sortByDirection}
        //     />
        //     <div className="tableBody relative">
        //       {isLoading ? (
        //         <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
        //       ) : (
        //         allClients.map((item, idx) => {
        //           return (
        //             <div key={idx} className={`flex-center tableRow`}>
        //               <div className="flex-center tdItem" onClick={() => handleSelect(item)}>
        //                 {item.name || "-"}
        //               </div>
        //               <div className="flex-center tdDistrict" onClick={() => handleSelect(item)}>
        //                 {AREAS[item.area] || "-"}
        //               </div>
        //               <div className="flex-center tdItem" onClick={() => handleSelect(item)}>
        //                 {item.contactPerson || "-"}
        //               </div>
        //               <div className="flex-center tdItem" onClick={() => handleSelect(item)}>
        //                 {item.tel || "-"}
        //               </div>
        //               <div className="tdLongItem" onClick={() => handleSelect(item)}>
        //                 {item.address || "-"}
        //               </div>
        //               <div className="flex-center tdDateItem" onClick={() => handleSelect(item)}>
        //                 {formatDateAndTime(item.updatedAt)}
        //               </div>
        //             </div>
        //           );
        //         })
        //       )}
        //       <DeleteModal
        //         isOpen={modal}
        //         deleteTarget={editTarget}
        //         setModal={setModal}
        //         addItemString={"客戶"}
        //       />
        //     </div>
        //   </div>
        // </div>
      )}
      {/* <ViewClientModal /> */}
    </div>
  );
}
