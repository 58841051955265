import axios, { AxiosResponse } from "axios";
// import { NewTemplateIcon, PrintIcon } from "components/IconsOnly";
// import SignatureSection from "components/parts/Document/SignatureSection";
import ViewCoHeader, { ViewCoFooter } from "components/ViewcoHeader";
import "css/Document.css";
import { IDocumentById, IDocumentTypesById } from "models/responseModels";
import React, { useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-autosize-textarea";
import { FaArrowCircleLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, Col, Container, Modal, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setDocumentData } from "redux/Document/action";
import { setChangesMade, setMessageModal } from "redux/Loading/action";
import { setSelectedDocumentModalOpen } from "redux/ServiceDocument/action";
import { IRootState } from "store";
import JobCardMaintenanceAgreement from "./Documents/JobCardMaintenanceAgreement";

interface DocumentByIdProps {
  isSelectionMode?: boolean;
}

export interface PostContent {
  docFieldId: number;
  fieldName: string;
  contentId?: number;
  content: string;
}

function DocumentById({ isSelectionMode = false }: DocumentByIdProps) {
  const printRef = useRef<HTMLTableElement>(null);
  const [allFields, setAllFields] = useState<Array<IDocumentById>>([]);
  const [postContent, setPostContent] = useState<{
    docName: string;
    docTypeId: number;
    fields: PostContent[];
  }>({
    docName: "",
    docTypeId: -1,
    fields: [],
  });
  const [saveDocumentModalOpen, setSaveDocumentModalOpen] = useState(false);
  const pathname = window.location.pathname.split("/");
  const docTypeId = pathname[pathname.length - 1];
  const history = useHistory();
  const dispatch = useDispatch();
  const documentData = useSelector((state: IRootState) => state.documents.documentData);

  useEffect(() => {
    if (isSelectionMode) return;
    const fetchDocTypeById = async () => {
      try {
        const url = new URL(`${process.env.REACT_APP_API_PATH}/doc-types`);
        url.searchParams.set("id", String(docTypeId));
        const res = await axios.get<IDocumentTypesById>(url.toString());
        const result = await res.data;
        dispatch(
          setDocumentData({
            id: Number(docTypeId),
            formNo: result.formNo,
            name: result.name,
            division: result.division,
            docCount: result.docCount,
            description: result.description,
          })
        );
      } catch (error) {
        dispatch(handleAxiosError(error));
      }
    };
    fetchDocTypeById();
  }, []);

  useEffect(() => {
    const fetchDocumentFields = async () => {
      try {
        let res: AxiosResponse;
        if (isSelectionMode) {
          res = await axios.get(`/doc-type-fields/${documentData.id}`);
        } else {
          res = await axios.get(`/doc-type-fields/${docTypeId}`);
        }
        const result = res.data.data;

        if (isSelectionMode) {
          setPostContent({
            docName: "",
            docTypeId: documentData.id,
            fields: result.map((i: any) => {
              return { docFieldId: i.id, fieldName: i.fieldName, content: "" };
            }),
          });
        } else {
          setAllFields(result);
        }
      } catch (error) {
        dispatch(handleAxiosError(error));
      }
    };
    fetchDocumentFields();
    return () => {
      setAllFields([]);
    };
  }, []);

  const handleExit = () => {
    if (isSelectionMode) {
      dispatch(setSelectedDocumentModalOpen(false));
    } else {
      history.goBack();
    }
  };

  const postDocumentToOrder = async () => {
    const pathname = window.location.pathname.split("/");
    const orderId = pathname[pathname.length - 1];
    try {
      const res = await axios.post(`/doc/${orderId}`, {
        docInfo: {
          name: postContent.docName,
          docTypeId: postContent.docTypeId,
        },
      });
      const documentId = await res.data.id;
      await axios.post(`/doc-content/${documentId}`, {
        docFields: postContent.fields,
      });
      dispatch(setChangesMade(true));
      setSaveDocumentModalOpen(false);
      //set modal off
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
      handleExit();
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };

  const handleEditContent = (idx: number, input: string) => {
    const newArr = postContent.fields.slice();
    newArr[idx] = {
      ...postContent.fields[idx],
      content: input,
    };
    setPostContent({
      docName: postContent.docName,
      docTypeId: postContent.docTypeId,
      fields: newArr,
    });
  };

  return (
    <Container className="p-4 flex-column-start viewDocumentPageContainer">
      <section className="mb-3 flex-row-between viewDocumentPageTopRow">
        <div className="d-flex align-items-center">
          <FaArrowCircleLeft size={32} onClick={handleExit} className="pointer" />
        </div>
        <div>
          <div className="d-flex justify-content-center">
            <h5 style={{ marginBottom: 0, textAlign: "center" }}>
              {documentData.formNo}
              <br />
              {documentData.name}
            </h5>
          </div>
        </div>
        <div />
      </section>
      <div />
      <div className="full-width flex-center">
        <div
          className="flex-center"
          onClick={() => {
            setSaveDocumentModalOpen(true);
          }}
        >
          <Button color="primary" className="my-4 toggleButtonText">
            儲存文件
          </Button>
        </div>
      </div>
      <div className="A4paper">
        <table ref={printRef} id="viewcoTable" style={{ width: "100%" }}>
          <thead>
            <div id="viewCoHeader" className="full-width flex-row-start viewCoHeader">
              <ViewCoHeader />
            </div>
          </thead>
          <tbody className="flex-center" id="viewcoContent" style={{ width: "100%" }}>
            <div id="viewcoContent" style={{ width: "80%", overflowX: "auto" }}>
              {documentData.name === "JOB CARD – MAINTENANCE AGREEMENT 維修保養工作單" ? (
                <JobCardMaintenanceAgreement
                  isSelectionMode={isSelectionMode}
                  allFields={allFields}
                  postContent={postContent}
                  setPostContent={setPostContent}
                />
              ) : (
                <>
                  {!isSelectionMode
                    ? allFields.map((item, idx) => {
                        return (
                          <tr
                            key={item.id}
                            className="my-1 d-flex formFields"
                            style={{ alignItems: "flex-end", pageBreakInside: "avoid" }}
                          >
                            <div
                              style={{
                                minWidth: "max-content",
                                color: "#888",
                              }}
                            >
                              {item.fieldName + " :"}
                            </div>
                            <input
                              className="inputStyle"
                              readOnly
                              value={""}
                              style={{ pointerEvents: "none", marginLeft: "8px" }}
                            />
                          </tr>
                        );
                      })
                    : postContent.fields.map((item, idx) => {
                        return (
                          <tr
                            key={item.docFieldId}
                            className="my-1 d-flex formFields"
                            style={{
                              alignItems: "flex-start",
                              pageBreakInside: "avoid",
                              minWidth: "400px",
                              justifyContent: "flex-start",
                            }}
                          >
                            <div
                              id={"fieldNameMargin"}
                              style={{
                                minWidth: "max-content",
                                color: "#888",
                              }}
                            >
                              {item.fieldName + " :"}
                            </div>
                            <TextareaAutosize
                              id={item.content ? "textAreaMargin" : "empty"}
                              className="inputStyle p-0 px-1 mx-1"
                              value={item.content}
                              onChange={(e) => handleEditContent(idx, e.currentTarget.value)}
                            />
                          </tr>
                        );
                      })}
                </>
              )}
              <div style={{ height: "40px" }} />
            </div>
          </tbody>
          <tfoot>
            <div style={{ height: "100px" }}></div>
            <div id="viewCoFooter" className="full-width flex-row-start viewCoHeader">
              <ViewCoFooter />
            </div>
          </tfoot>
        </table>
      </div>
      {isSelectionMode && saveDocumentModalOpen && (
        <Modal size="md" isOpen={saveDocumentModalOpen}>
          <Container
            size="lg"
            className="p-4 d-flex flex-column justify-content-around"
            style={{ minHeight: "200px" }}
          >
            <Row>
              <Col className="flex-center">
                <h5>儲存文件</h5>
              </Col>
            </Row>
            <Row>
              <Col md={1} style={{ width: "max-content" }}>
                文件名稱
              </Col>
              <Col>
                <input
                  className="inputStyle"
                  value={postContent.docName}
                  onChange={(e) => {
                    setPostContent({ ...postContent, docName: e.target.value });
                  }}
                />
              </Col>
            </Row>
            <Row className="flex-center mt-3">
              <Col md={6} className="my-2 flex-center">
                <Button style={{ width: "100%" }} onClick={() => setSaveDocumentModalOpen(false)}>
                  取消
                </Button>
              </Col>
              <Col md={6} className="flex-center">
                <Button color="primary" style={{ width: "100%" }} onClick={postDocumentToOrder}>
                  儲存
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal>
      )}
    </Container>
  );
}

export default DocumentById;
