import { ScreenIndicatorActions } from './action';
import { ScreenIndicatorState } from './state';

export const initialState: ScreenIndicatorState = {
  display: '',
};

export default function screenIndicatorReducer(
  state: ScreenIndicatorState = initialState,
  action: ScreenIndicatorActions
) {
  switch (action.type) {
    case '@@ScreenIndicator/setScreenIndicatorText':
      return {
        ...state,
        display: action.text,
      };

    default:
      return state;
  }
}
