import axios from "axios";
import "css/Document.css";
import { pageStyle } from "css/printCss";
import { formatDateAndTime } from "helpers/date";
import { ITemplateById } from "models/responseModels";
import { DivisionType } from "models/variables";
import React, { useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-autosize-textarea/lib";
import { isSafari } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { Button, Col, Container, Modal, Row, Spinner } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setChangesMade, setMessageModal } from "redux/Loading/action";
import { setNewDocumentModalOpen } from "redux/ServiceDocument/action";
import {
  setTemplateChangesMade,
  setTemplateContentArray,
  setTemplateData
} from "redux/Template/action";
import { IRootState } from "store";
import DeleteModal from "./DeleteModal";
import { SelectedJobCardMaintenanceAgreement } from "./Documents/JobCardMaintenanceAgreement";
import { AddIcon, CloseIcon, DeleteIcon, DocumentEditIcon } from "./IconsOnly";
import JobCompletionSignatureFields from "./JobCompletionSignatureFields";
import ViewCoHeader, { ViewCoFooter } from "./ViewcoHeader";

interface IServiceDocument {
  id: number;
  name: string;
  division: DivisionType | null;
  createdAt: string;
  formNo: string;
  updatedAt: string;
  docTypeId: number;
  docTypeName: string;
  docTypeDescription: string;
  docTypeIsActive: boolean;
  date: string;
  isTemplate: boolean;
}

interface DocumentsProps {
  readOnly?: boolean;
}

function Documents({ readOnly = false }: DocumentsProps) {
  const printRef = useRef<HTMLTableElement>(null);
  const [selectModal, setSelectModal] = useState(false);
  const [isEditingDocName, setIsEditingDocName] = useState(false);
  const [allFields, setAllFields] = useState<Array<ITemplateById>>([]);
  const [serviceDocument, setServiceDocument] = useState<IServiceDocument[]>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<{ name: string; id: number }>({
    name: "",
    id: -1,
  });
  const [originalName, setOriginalName] = useState("");
  const pathname = window.location.pathname.split("/");
  const orderId = pathname[pathname.length - 1];
  const dispatch = useDispatch();
  const isLoading = useSelector((state: IRootState) => state.loading.isLoading);
  const changesMade = useSelector((state: IRootState) => state.loading.changesMade);
  const templates = useSelector((state: IRootState) => state.templates);
  const templatesData = templates.templateData;
  const userRole = useSelector((state: IRootState) => state.auth.role);

  //   const templateContentArray = templates.templateContentArray;

  const fetchAllDocuments = async (pageNumber: number) => {
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/doc`);
      url.searchParams.set("orderId", orderId.toString());
      url.searchParams.set("rows", "10");
      url.searchParams.set("page", pageNumber.toString());
      const res = await axios.get(url.toString());
      const result = res.data;
      setServiceDocument(result.data);
      //   dispatch(setTemplatePagination(result.pagination));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };
  const fetchAllFields = async (serviceDocumentId: number) => {
    try {
      const res = await axios.get(`/doc-content/${serviceDocumentId}`);
      const result = await res.data.data;
      setAllFields(result);
      dispatch(setTemplateContentArray(result));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };
  const handleSelectDocument = (item: IServiceDocument) => {
    dispatch(setTemplateData(item));
    setOriginalName(item.name);
    fetchAllFields(item.id);
    setSelectModal(true);
  };
  const putEditDocumentName = async () => {
    try {
      await axios.put(`/doc/${templatesData.id}`, {
        docInfo: {
          name: originalName,
        },
      });
      //   setOriginalName(templates.templateData.name);
      setIsEditingDocName(false);
      dispatch(setChangesMade(true));
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };
  const putEditedContent = async () => {
    const postContent = allFields.map((i) => {
      return { contentId: i.contentId, content: i.content };
    });
    try {
      await axios.put(`/doc-content`, {
        content: postContent,
      });
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
      fetchAllDocuments(1);
      //   setSelectModal(false);
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };
  const handleEdit = (idx: number, value: string) => {
    const newArr = allFields.slice();
    newArr[idx] = {
      ...newArr[idx],
      content: value,
    };
    setAllFields(newArr);
  };
  useEffect(() => {
    fetchAllDocuments(1);
  }, []);
  useEffect(() => {
    if (changesMade || templates.changesMade) {
      fetchAllDocuments(1);
      dispatch(setChangesMade(false));
      dispatch(setTemplateChangesMade(false));
    }
  }, [changesMade]);

  return (
    <section className="pt-2 my-2 sectionContainer flex-center">
      <div
        className="my-2 px-2 flex-column-start full-width"
        style={{ alignItems: "flex-start", position: "relative" }}
      >
        <div className="px-2 mx-2 sectionHead flex-row-between">
          <div className="mb-2">{"文件"}</div>
          {!readOnly && (
            <div
              className="mb-2 px-2 flex-center full-height pointer"
              onClick={() => dispatch(setNewDocumentModalOpen(true))}
            >
              <AddIcon />
              <div className="mx-2">加入文件</div>
            </div>
          )}
        </div>
        <div
          className="full-width flex-column-start"
          style={{
            overflowX: "auto",
            alignItems: !serviceDocument.length ? "center" : "flex-start",
          }}
        >
          <div className="flex-row-start">
            {!serviceDocument.length ? (
              <Row className="my-1 d-flex justify-content-center" style={{ minHeight: "64px" }}>
                <Col className="flex-center">
                  <div className="disableText">暫無資料</div>
                </Col>
              </Row>
            ) : isLoading ? (
              <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
            ) : (
              <div className="flex-row-start full-width" style={{ overflowX: "auto" }}>
                <div className="p-2 viewCoTable">
                  <div className="relative">
                    {serviceDocument.map((item, idx) => {
                      return (
                        <div key={idx} className={`p-2 flex-center tableRow`}>
                          {!readOnly && userRole !== "WORKER" && (
                            <div className="mx-1">
                              <div
                                className="flex-center  full-height"
                                onClick={() => {
                                  setDeleteModal(!deleteModal);
                                  setDeleteTarget({ name: item.name, id: item.id });
                                }}
                              >
                                <DeleteIcon />
                              </div>
                            </div>
                          )}
                          <div
                            className="mx-3"
                            onClick={() => handleSelectDocument(item)}
                            style={{
                              maxWidth: "140px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item.name || "-"}
                          </div>
                          <div className="mx-3" onClick={() => handleSelectDocument(item)}>
                            {item.docTypeDescription || "-"}
                          </div>
                          <div className="mx-3" onClick={() => handleSelectDocument(item)}>
                            {formatDateAndTime(item.updatedAt) || "-"}
                          </div>
                        </div>
                      );
                    })}
                    <DeleteModal
                      isOpen={deleteModal}
                      deleteTarget={deleteTarget}
                      setModal={setDeleteModal}
                      addItemString={"文件"}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <Modal centered isOpen={selectModal}>
            <Container className="p-3 full-size" style={{ maxHeight: "95vh", overflowY: "auto" }}>
              {/* <Col md={4}> */}
              <div className="flex-column-center full-width relative">
                <Row className="full-width">
                  <Col className="flex-row-end">
                    <div className="pointer" onClick={() => setSelectModal(false)}>
                      <CloseIcon />
                    </div>
                  </Col>
                </Row>
                <div className="m-2 flex-center">
                  <div
                    style={{
                      borderRadius: "50%",
                      padding: "12px",
                      background: "white",
                      boxShadow: "0 1px 4px #0003",
                    }}
                    onClick={() => {
                      setIsEditingDocName(!isEditingDocName);
                    }}
                  >
                    <DocumentEditIcon />
                  </div>
                  {isEditingDocName ? (
                    <input
                      className="mx-2 inputStyle"
                      value={originalName}
                      autoFocus
                      onChange={(e) => setOriginalName(e.target.value)}
                    />
                  ) : (
                    <h6
                      className="flex-center mx-2 w-90"
                      style={{ marginBottom: 0, textAlign: "center", position: "relative" }}
                    >
                      {originalName}
                    </h6>
                  )}
                  {isEditingDocName && (
                    <Button color="primary" onClick={putEditDocumentName}>
                      SAVE
                    </Button>
                  )}
                </div>
              </div>
              {/* </Col> */}
              {/* </Col> */}
              {/* </Col> */}
              {/* </Col> */}
              {/* </Col> */}
              {/* </Col> */}
              {/* </Col> */}
              {/* </Col> */}
              {/* </Col> */}
              {/* {allFields.map((item, idx) => {
                return (
                  <tr
                    key={item.docFieldId}
                    className="my-1 d-flex formFields"
                    style={{ alignItems: "flex-start", pageBreakInside: "avoid" }}
                  >
                    <div
                      id={"fieldNameMargin"}
                      style={{
                        minWidth: "max-content",
                        color: "#888",
                      }}
                    >
                      {item.fieldName + " :"}
                    </div>
                    <TextareaAutosize
                      id={item.content ? "textAreaMargin" : "empty"}
                      className="inputStyle p-0 px-1 mx-1"
                      value={item.content}
                      onChange={(e) => handleEdit(idx, e.currentTarget.value)}
                    />
                  </tr>
                );
              })} */}
              {/* </Col> */}
              {/* </Col> */}
              {/* </Col> */}
              {/* </Col> */}
              {/* </Col> */}
              {/* </Col> */}
              {/* </Col> */}
              {/* </Col> */}
              {/* </Col> */}

              <table style={{ width: "100%" }}>
                <tbody className="flex-center full-width" style={{ width: "100%" }}>
                  <div>
                    {allFields.map((item, idx) => {
                      if (
                        templates.templateData.docTypeName ===
                          "JOB CARD – MAINTENANCE AGREEMENT 維修保養工作單" &&
                        idx > allFields.length - 4
                      ) {
                        return <></>;
                      }
                      return (
                        <tr
                          key={item.docFieldId}
                          className="my-1 d-flex formFields"
                          style={{
                            alignItems: "flex-start",
                            pageBreakInside: "avoid",
                            minWidth: "400px",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div
                            id={"fieldNameMargin"}
                            style={{
                              minWidth: "max-content",
                              color: "#888",
                            }}
                          >
                            {item.fieldName + " :"}
                          </div>
                          <TextareaAutosize
                            id={item.content ? "textAreaMargin" : "empty"}
                            className="inputStyle p-0 px-1 mx-1"
                            value={item.content}
                            style={{ width: "100%" }}
                            onChange={(e) => handleEdit(idx, e.currentTarget.value)}
                          />
                        </tr>
                      );
                    })}
                  </div>
                </tbody>
              </table>

              {/* <Col/> */}
              {/* <Col/> */}
              {/* <Col/> */}
              {/* <Col/> */}
              {/* <Col/> */}
              {/* <Col/> */}
              {/* <Col/> */}
              {/* <Col/> */}
              {/* <Col/> */}
              {/* <Col/> */}
              {/* <Col/> */}
              {/* <Col/> */}
              {/* <Col/> */}
              <Row className="my-3 flex-column-center">
                <Button className="my-2 w-40" color="primary" onClick={putEditedContent}>
                  儲存
                </Button>
                <ReactToPrint
                  bodyClass={"contentContainer"}
                  pageStyle={pageStyle}
                  content={() => printRef.current}
                  //   documentTitle={documentData.formNo}
                  trigger={() => (
                    // <div className="printButtonContainer mx-2">
                    <Button className="flex-center w-40" color="primary">
                      列印文件
                    </Button>
                    //   <div className="toggleButtonText">列印文件</div>
                    // </div>
                  )}
                />
                <Row className="mb-4" />
              </Row>
            </Container>
          </Modal>
        </div>
        <div className="d-none m-0">
          <table
            ref={printRef}
            className="mt-0 pt-0"
            id={isSafari ? "viewcoTable" : "viewcoTableOther"}
            style={{
              display: "block",
            }}
          >
            <thead className="full-width">
              <div id="viewCoHeader" className="full-width flex-row-start viewCoHeader">
                <ViewCoHeader />
              </div>
            </thead>
            <tbody
              className="flex-center relative"
              id="viewcoContent"
              style={{
                width: "160mm",
                maxHeight: "160mm",
                pageBreakInside: "auto",
              }}
            >
              <h5 className="my-4 flex-center">{templates.templateData.docTypeName}</h5>
              {templates.templateData.docTypeName ===
              "JOB CARD – MAINTENANCE AGREEMENT 維修保養工作單" ? (
                <SelectedJobCardMaintenanceAgreement allFields={allFields} handleEdit={() => {}} />
              ) : (
                allFields.map((item, idx) => {
                  return (
                    <tr
                      id="viewcoRow"
                      key={item.docFieldId}
                      className="my-1 d-flex formFields"
                      style={{
                        alignItems: "flex-start",
                        pageBreakInside: "avoid",
                      }}
                    >
                      <div
                        id={"fieldNameMargin"}
                        style={{
                          minWidth: "max-content",
                          pageBreakInside: "avoid",
                          color: "#444",
                        }}
                      >
                        {item.fieldName + " :"}
                      </div>
                      <TextareaAutosize
                        readOnly
                        id={item.content ? "textAreaMargin" : "empty"}
                        className="inputStyle p-0 px-1 mx-1"
                        value={item.content}
                        style={{ width: "100%", pageBreakInside: "avoid" }}
                      />
                    </tr>
                  );
                })
              )}
              <tr
                id="jobCompletionSignatureFields"
                className="full-width formFields jobCompletionSignatureFields"
                style={{ display: "block", alignItems: "center", pageBreakInside: "avoid" }}
              >
                <JobCompletionSignatureFields />
              </tr>
              <tr className="my-3">
                <div id="viewCoFooter" className="full-width flex-row-start">
                  <ViewCoFooter />
                </div>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default Documents;
