import axios from "axios";
import { DownCaret, EditIcon, VeryUrgentIcon } from "components/IconsOnly";
import "css/OrderByIdPage.css";
import { formatDateAndTimeWithUnknown, setDateToStart, timeFormatter } from "helpers/date";
import { midLevelUp } from "models/authorities";
import { OrderById } from "models/responseModels";
import { ProgressStatusType, PROGRESS_STATUS, PROGRESS_STATUS_MAPPING } from "models/variables";
import React, { useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-autosize-textarea";
import DatePicker from "react-date-picker";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setMessageModal } from "redux/Loading/action";
import { setOrderByIdData } from "redux/Order/action";
import { IRootState } from "store";

interface OrderRowProps {
  keyText?: string;
  valueText: string;
  index?: {
    [K in keyof OrderById]: OrderById[K] extends string | null ? K : never;
  }[keyof OrderById];
  putFunction?: () => void;
}

export function DateTimeSectionRow(props: OrderRowProps) {
  const { keyText, valueText, index, putFunction } = props;
  const [canEdit, setCanEdit] = useState(false);
  const [originalText, setOriginalText] = useState("");
  const userRole = useSelector((state: IRootState) => state.auth.role);
  const data = useSelector((state: IRootState) => state.orderById.orderById);
  const dispatch = useDispatch();
  const canEditDateUsers = ["FOREMEN", "SUPERVISOR", "SUPER_ADMIN"];

  useEffect(() => {
    if (canEdit) {
      setOriginalText(valueText);
      if (valueText === "-") {
        dispatch(setOrderByIdData({ ...data, [index!]: "" }));
      }
    }
  }, [canEdit]);

  return (
    <Row
      className="my-2 px-1 flex-center relative"
      style={{ minHeight: "24px", alignItems: "flex-start" }}
    >
      <div
        className="w-35 flex-row-start"
        style={{
          borderRight: "solid 1px #AAA",
          overflowWrap: "anywhere",
          alignItems: "flex-start",
          textAlign: "left",
        }}
      >
        <div>{keyText}</div>
      </div>
      <div
        className="px-3 w-60 flex-row-start"
        style={{
          alignItems: "flex-start",
          overflowWrap: "anywhere",
          textAlign: "left",
        }}
      >
        {canEdit && canEditDateUsers.includes(userRole) ? (
          <div className="flex-column-start" style={{ alignItems: "flex-start" }}>
            <DatePicker
              calendarType={"US"}
              minDate={
                index === "startDate"
                  ? userRole === "SUPER_ADMIN"
                    ? undefined
                    : setDateToStart(new Date(Date.now()).toISOString())
                  : !data.startDate
                  ? userRole === "SUPER_ADMIN"
                    ? undefined
                    : setDateToStart(new Date(Date.now()).toISOString())
                  : new Date(data.startDate)
              }
              format={"y-MM-dd"}
              onChange={(e: Date) => {
                if (index === "startDate") {
                  if (!e) {
                    dispatch(setOrderByIdData({ ...data, startDate: null }));
                  } else {
                    if (
                      !!data.completeDate &&
                      setDateToStart(data.completeDate).valueOf() <
                        setDateToStart(e.toISOString()).valueOf()
                    ) {
                      dispatch(
                        setOrderByIdData({
                          ...data,
                          startDate: e.toISOString(),
                          completeDate: null,
                        })
                      );
                    } else {
                      dispatch(setOrderByIdData({ ...data, startDate: e.toISOString() }));
                    }
                  }
                } else if (index === "completeDate") {
                  if (!e) {
                    dispatch(setOrderByIdData({ ...data, completeDate: null }));
                  } else {
                    dispatch(setOrderByIdData({ ...data, completeDate: e.toISOString() }));
                  }
                }
              }}
              value={!data[index!] ? null : new Date(data[index!]!)}
              className="orderByIdCalendar"
              calendarClassName="calendar"
            />

            <input
              className="timePicker full-width my-1"
              disabled={!data[index!]!}
              type="time"
              value={!!data[index!]! ? timeFormatter.format(new Date(data[index!]!)) : undefined}
              onChange={(e) => {
                if (!data[index!]!) {
                  return;
                } else {
                  const target = e.target.value.split(":"); // turn the user input into numbers
                  const joinTime = new Date(data[index!]!).setHours(
                    parseInt(target[0]),
                    parseInt(target[1])
                  );
                  dispatch(
                    setOrderByIdData({ ...data, [index!]: new Date(joinTime).toISOString() })
                  );
                }
              }}
            />
          </div>
        ) : (
          <div>{valueText ? formatDateAndTimeWithUnknown(valueText) : "-"}</div>
        )}
        {canEditDateUsers.includes(userRole) && (
          <div
            className="mx-3 absolute"
            style={{ right: canEdit ? 24 : 64, top: canEdit ? 5 : 2, transition: "all 0.3s" }}
            onClick={
              canEdit
                ? () => {
                    setCanEdit(false);
                    dispatch(setOrderByIdData({ ...data, [index!]: originalText ?? "" }));
                  }
                : () => setCanEdit(true)
            }
          >
            <EditIcon clicked={canEdit} idx={index!} />
          </div>
        )}
        {canEdit && (
          <Button
            color="primary"
            className="py-0 px-3 flex-center absolute"
            disabled={originalText === valueText}
            style={{
              height: "24px",
              whiteSpace: "nowrap",
              right: 16,
              top: 36,
              transition: "all 0.4s",
            }}
            onClick={() => {
              if (putFunction) {
                putFunction();
                setCanEdit(false);
              }
            }}
          >
            儲存
          </Button>
        )}
      </div>
    </Row>
  );
}
export function DynamicsSectionRow(props: OrderRowProps) {
  const { keyText, valueText } = props;

  return (
    <Row className="my-2 px-1 flex-center" style={{ minHeight: "24px", alignItems: "flex-start" }}>
      <div
        className="w-45 flex-row-start"
        style={{
          borderRight: "solid 1px #AAA",
          overflowWrap: "anywhere",
          alignItems: "flex-start",
          textAlign: "left",
        }}
      >
        <div>{keyText}</div>
      </div>
      <div
        className="px-3 w-50 flex-row-start"
        style={{
          alignItems: "flex-start",
          overflowWrap: "anywhere",
          textAlign: "left",
        }}
      >
        <div>{valueText}</div>
        {valueText.includes("e-call") && (
          <div className="mx-2" style={{ transform: "translateY(-2px)" }}>
            <VeryUrgentIcon />
          </div>
        )}
      </div>
    </Row>
  );
}

interface SectionReportProps {
  idx: string;
  keyText: string;
  valueText: string;
  onSubmit: () => void;
  editText: (args0: {
    serviceSubject?: string | undefined;
    serviceReport?: string | undefined;
  }) => void;
  activateEdit?: (args0: boolean) => void;
  isEditMode: boolean;
  readOnly?: boolean;
}

export function SectionReport(props: SectionReportProps) {
  const {
    idx,
    keyText,
    valueText,
    onSubmit,
    editText,
    isEditMode = false,
    activateEdit,
    readOnly = false,
  } = props;

  const [originalValue, setOriginalValue] = useState(valueText);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const auth = useSelector((state: IRootState) => state.auth);
  const userRole = auth.role;
  const dispatch = useDispatch();
  const handleSubmit = () => {
    if (activateEdit) activateEdit(false);
    onSubmit();
  };
  useEffect(() => {
    if (!isEditMode) return;
    inputRef.current?.focus();
    setOriginalValue(valueText);
    if (valueText === "-") {
      editText({ [idx]: "" });
    }
  }, [isEditMode]);

  return (
    <div
      className="my-2 px-2 flex-column-start full-width"
      style={{ alignItems: "flex-start", position: "relative" }}
    >
      <div className="px-2 mx-2 sectionHead">
        <div className="mb-2">{keyText}</div>
        {!readOnly && activateEdit && midLevelUp.includes(userRole) && (
          <div
            onClick={
              isEditMode
                ? () => {
                    activateEdit(false);
                    dispatch(editText({ [idx]: originalValue ?? "" }));
                  }
                : () => activateEdit(true)
            }
          >
            <EditIcon clicked={isEditMode} idx={idx ? idx : "1"} />
          </div>
        )}
        {!readOnly && activateEdit && userRole === "WORKER" && idx === "serviceReport" && (
          <div
            onClick={
              isEditMode
                ? () => {
                    activateEdit(false);
                    dispatch(editText({ [idx]: originalValue ?? "" }));
                  }
                : () => activateEdit(true)
            }
          >
            <EditIcon clicked={isEditMode} idx={idx ? idx : "1"} />
          </div>
        )}
      </div>
      <div className="px-3 full-width flex-column-start">
        {isEditMode ? (
          <>
            <TextareaAutosize
              ref={inputRef}
              rows={5}
              className="my-2 full-width"
              style={{ borderColor: "#AAA", borderRadius: "3px" }}
              value={valueText}
              onChange={(e) => dispatch(editText({ [idx]: e.currentTarget.value }))}
            />
            <Button
              className="w-40"
              disabled={originalValue === valueText}
              color="primary"
              onClick={handleSubmit}
            >
              儲存
            </Button>
          </>
        ) : (
          <TextareaAutosize
            value={valueText}
            readOnly
            className="my-2 full-width"
            style={{ textAlign: "left", border: "none", background: "none" }}
          />
        )}
      </div>
    </div>
  );
}

export function ProgressStatusDropDown() {
  const [progressDropDown, setProgressDropDown] = useState(false);
  const [originalProgressStatus, setOriginalProgressStatus] = useState<ProgressStatusType | null>(
    null
  );
  const data = useSelector((state: IRootState) => state.orderById.orderById);
  const auth = useSelector((state: IRootState) => state.auth);
  const userRole = auth.role;
  const dispatch = useDispatch();

  const handleSelectProgress = (item: ProgressStatusType) => {
    if (item === "COMPLETED" && !data.acjNo) {
      dispatch(setMessageModal({ isOpen: true, content: "尚未輸入 ACJ 號碼 / P.O. 號碼" }));
      return;
    } else if (item === "COMPLETED" && !data.surveyCompleted) {
      dispatch(setMessageModal({ isOpen: true, content: "尚未有評分或客戶簽署" }));
    } else if (item === "COMPLETED" && !data.serviceReport) {
      dispatch(setMessageModal({ isOpen: true, content: "尚未輸入服務報告" }));
    } else if (item !== data.progressStatus) {
      dispatch(setOrderByIdData({ ...data, progressStatus: item }));
      setProgressDropDown(false);
    }
  };

  const handleSubmitProgressStatus = async () => {
    try {
      await axios.put(`/orders/${data.id}`, {
        order: { progressStatus: data.progressStatus },
      });
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
      setOriginalProgressStatus(data.progressStatus);
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };

  useEffect(() => {
    setOriginalProgressStatus(data.progressStatus);
  }, [dispatch, data.id]);

  return (
    <Row className="mb-2 px-1 flex-center" style={{ maxHeight: "24px", marginTop: "-2px" }}>
      <div
        className="w-35 flex-row-start"
        style={{
          maxHeight: "24px",
          borderRight: "solid 1px #AAA",
        }}
      >
        <div>{"進度"}</div>
      </div>
      <div
        className="w-60 flex-row-start"
        style={{
          maxHeight: "24px",
          paddingRight: 0,
        }}
      >
        <Dropdown
          className="p-0 flex-row-start full-width"
          isOpen={progressDropDown}
          toggle={() => {
            setProgressDropDown(!progressDropDown);
          }}
        >
          {PROGRESS_STATUS[data.progressStatus]}
          <DropdownToggle
            className="p-0 mx-4"
            style={{
              maxHeight: "24px",
              border: "none",
              width: "max-content",
              background: "none",
              boxShadow: "0 0 0 #000",
            }}
          >
            <div
              className="flex-center"
              style={{
                height: "24px",
                transform: progressDropDown ? "rotate(180deg)" : "rotate(0deg)",
                transition: "all 0.3s",
              }}
            >
              <DownCaret />
            </div>
          </DropdownToggle>
          <DropdownMenu right={true}>
            {Object.values(PROGRESS_STATUS).map((item) => {
              return (
                <DropdownItem
                  key={item}
                  disabled={item === PROGRESS_STATUS["COMPLETED"] && userRole === "ADMIN"}
                  md={2}
                  className="d-flex flex-center districtSelectionItem"
                  onClick={() => {
                    handleSelectProgress(PROGRESS_STATUS_MAPPING.get(item));
                  }}
                  style={{ color: "var(--primaryTextColor)" }}
                >
                  {item}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
        <Button
          color="primary"
          className="py-0 px-3 flex-center"
          disabled={originalProgressStatus === data.progressStatus}
          style={{ height: "24px", whiteSpace: "nowrap" }}
          onClick={handleSubmitProgressStatus}
        >
          儲存
        </Button>
      </div>
    </Row>
  );
}
