import axios from "axios";
import InfoCard from "components/InfoCard";
import SearchBar from "components/SearchBar";
import "css/OrderListPage.css";
import { IOrders, IPagination } from "models/responseModels";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { handleAxiosError } from "redux/Auth/action";
import { setIsLoading } from "redux/Loading/action";

function OrderHistoryPage() {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [pagination, setPagination] = useState<IPagination | null>(null);
  const [searchText, setSearchText] = useState("");
  const [fetchedOrders, setFetchedOrders] = useState<Array<IOrders>>([]);
  const dispatch = useDispatch();

  const fetchAllOrders = async (pageNumber: number) => {
    dispatch(setIsLoading(true));
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/orders`);
      url.searchParams.set("rows", "10");
      url.searchParams.set("page", pageNumber.toString());
      if (!!searchText) url.searchParams.set("search", searchText);
      const res = await axios.get(url.toString());
      const result = res.data;
      setFetchedOrders(result.data);
      setPagination(result.pagination);
    } catch (error) {
      dispatch(handleAxiosError(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleSearch = () => {
    fetchAllOrders(1);
  };

  useEffect(() => {
    fetchAllOrders(1);
  }, [dispatch]);

  useEffect(() => {
    if (pagination) {
      fetchAllOrders(pagination?.currentPage);
      scrollRef.current?.scrollTo(32 * (pagination.currentPage - 1), 0);
    }
  }, [pagination?.currentPage]);

  return (
    <div
      className="full-width"
      style={{
        height: "100vh",
        overflowY: "auto",
        paddingBottom: "10em",
      }}
    >
      <SearchBar
        searchText={searchText}
        setSearchText={setSearchText}
        handleSearch={handleSearch}
      />
      <div className="flex-column-start relative" style={{ justifyContent: "flex-start", top: 0 }}>
        {!fetchedOrders.length ? (
          <h6 className="m-4" style={{ color: "#AAA" }}>
            無工作單
          </h6>
        ) : (
          fetchedOrders.map((item) => <InfoCard key={item.id} item={item} />)
        )}
      </div>
      {!fetchedOrders.length ? (
        <></>
      ) : (
        <div className="mt-4 flex-column-center">
          <section className="flex-center" style={{ maxWidth: "88vw" }}>
            <div
              className="mx-2 p-3 flex-center"
              style={{ minWidth: "64px", whiteSpace: "nowrap" }}
            >
              頁數
            </div>
            <div
              ref={scrollRef}
              className="p-2 flex-row-start"
              style={{
                scrollBehavior: "smooth",
                overflowX: "auto",
              }}
            >
              {pagination &&
                Array(pagination.lastPage)
                  .fill(0)
                  .map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        className="mx-1 flex-center"
                        style={{
                          background:
                            pagination.currentPage === idx + 1
                              ? "var(--navBackgroundColor)"
                              : "#FFF",
                          color:
                            pagination.currentPage === idx + 1
                              ? "var(--secondaryTextColor)"
                              : "var(--primaryTextColor)",
                          minWidth: pagination.currentPage === idx + 1 ? "48px" : "24px",
                          height: "24px",
                          borderRadius: "3px",
                          transition: "all 0.3s",
                        }}
                        onClick={() => setPagination({ ...pagination!, currentPage: idx + 1 })}
                      >
                        {idx + 1}
                      </div>
                    );
                  })}
            </div>
          </section>
          {pagination && pagination?.lastPage > 1 && (
            <section className="flex-row-around full-width">
              <div
                className="py-1 px-3"
                style={{ border: "solid 1px #555", borderRadius: "3px" }}
                onClick={() => setPagination({ ...pagination!, currentPage: 1 })}
              >
                第一頁
              </div>
              <div
                className="py-1 px-3"
                style={{ border: "solid 1px #555", borderRadius: "3px" }}
                onClick={() =>
                  setPagination({ ...pagination!, currentPage: pagination?.lastPage! })
                }
              >
                最後一頁
              </div>
            </section>
          )}
        </div>
      )}
    </div>
  );
}

export default OrderHistoryPage;
