export const setScreenIndicatorText = (text: string) => {
  return {
    type: '@@ScreenIndicator/setScreenIndicatorText' as const,
    text,
  };
};

type ActionCreators = typeof setScreenIndicatorText;

export type ScreenIndicatorActions = ReturnType<ActionCreators>;
