import { DocumentActions } from './action';
import { DocumentState } from './state';

export const initialState: DocumentState = {
  documentData: {
    description: '',
    division: null,
    docCount: 0,
    formNo: '',
    id: -1,
    name: '',
    updatedAt: '',
  },
  documentDataArray: [{ description: '', division: null, docCount: 0, formNo: '', id: -1, name: '', updatedAt: '' }],
  changesMade: false,
  pagination: {
    currentPage: 1,
    from: 1,
    lastPage: 1,
    perPage: 10,
    to: 1,
    total: 1,
  },
};

export default function documentReducer(state: DocumentState = initialState, action: DocumentActions) {
  switch (action.type) {
    case '@@Document/setDocumentDataArray':
      return {
        ...state,
        documentDataArray: action.data,
      };
    case '@@Document/setDocumentData':
      return {
        ...state,
        documentData: {
          description: action.data.description ?? state.documentData.description,
          division: action.data.division ?? state.documentData.division,
          docCount: action.data.docCount ?? state.documentData.docCount,
          formNo: action.data.formNo ?? state.documentData.formNo,
          id: action.data.id ?? state.documentData.id,
          name: action.data.name ?? state.documentData.name,
          updatedAt: action.data.updatedAt ?? state.documentData.updatedAt,
        },
      };
    case '@@Document/resetDocumentData':
      return {
        ...state,
        documentData: {
          ...initialState.documentData,
        },
      };
    case '@@Document/setChangesMade':
      return {
        ...state,
        changesMade: action.changesMade,
      };
    case '@@Pagination/setDocumentPagination':
      return {
        ...state,
        pagination: action.data,
      };
    case '@@Pagination/setDocumentCurrentPage':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: action.currentPage,
        },
      };
    case '@@Pagination/resetDocumentPagination':
      return {
        ...state,
        ...initialState.pagination,
      };

    default:
      return state;
  }
}
