import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { AnyAction, applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk, { ThunkDispatch as OldThunkDispatch } from 'redux-thunk';
import authReducer from 'redux/Auth/reducer';
import { IAuthState } from 'redux/Auth/state';
import documentReducer from 'redux/Document/reducer';
import { DocumentState } from 'redux/Document/state';
import loadingReducer from 'redux/Loading/reducer';
import { LoadingState } from 'redux/Loading/state';
import orderByIdReducer from 'redux/Order/reducer';
import { OrderByIdState } from 'redux/Order/state';
import screenIndicatorReducer from 'redux/ScreenIndicator/reducer';
import { ScreenIndicatorState } from 'redux/ScreenIndicator/state';
import newDocumentReducer from 'redux/ServiceDocument/reducer';
import { NewDocumentState } from 'redux/ServiceDocument/state';
import templateReducer from 'redux/Template/reducer';
import { TemplateState } from 'redux/Template/state';

export const history = createBrowserHistory();
export type ThunkDispatch = OldThunkDispatch<IRootState, null, AnyAction>;

export interface IRootState {
  screenIndicator: ScreenIndicatorState;
  router: RouterState;
  auth: IAuthState;
  orderById: OrderByIdState;
  loading: LoadingState;
  documents: DocumentState;
  templates: TemplateState;
  newDocument: NewDocumentState;
}

const rootReducer = combineReducers({
  screenIndicator: screenIndicatorReducer,
  router: connectRouter(history),
  auth: authReducer,
  orderById: orderByIdReducer,
  loading: loadingReducer,
  documents: documentReducer,
  templates: templateReducer,
  newDocument: newDocumentReducer,
});

// for REDUX middleware creation

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

declare global {
  /* tslint:disable:interface-name */
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

const middleWares = composeEnhancers(
  applyMiddleware(thunk),
  applyMiddleware(routerMiddleware(history))
);
export const store = createStore(rootReducer, middleWares);
