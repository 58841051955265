import { IPagination } from '../../models/responseModels';
import { SelectedDocumentType } from './state';

//pagination
export const setNewDocumentPagination = (data: IPagination) => {
  return {
    type: '@@Pagination/setNewDocumentPagination' as const,
    data,
  };
};

export const setNewDocumentCurrentPage = (currentPage: number) => {
  return {
    type: '@@Pagination/setNewDocumentCurrentPage' as const,
    currentPage,
  };
};
export const resetNewDocumentPagination = () => {
  return {
    type: '@@Pagination/resetNewDocumentPagination' as const,
  };
};

export const setNewDocumentChangesMade = (changesMade: boolean) => {
  return {
    type: '@@Pagination/setNewDocumentChangesMade' as const,
    changesMade,
  };
};
//end of pagination

export const setNewDocumentModalOpen = (isOpen: boolean) => {
  return {
    type: '@@ServiceDocument/setNewDocumentModalOpen' as const,
    isOpen,
  };
};
export const setSelectedDocumentModalOpen = (isOpen: boolean, documentType?: SelectedDocumentType) => {
  if (!documentType) documentType = '';
  return {
    type: '@@ServiceDocument/setSelectedDocumentModalOpen' as const,
    isOpen,
    documentType,
  };
};

export const setSelectedDocument = (data: { docTypeId?: number; templateId?: number; templateName?: string }) => {
  return {
    type: '@@ServiceDocument/setSelectedDocument' as const,
    data,
  };
};

type ActionCreators =
  | typeof setNewDocumentPagination
  | typeof setNewDocumentCurrentPage
  | typeof resetNewDocumentPagination
  | typeof setNewDocumentChangesMade
  | typeof setNewDocumentModalOpen
  | typeof setSelectedDocumentModalOpen
  | typeof setSelectedDocument;

export type NewDocumentActions = ReturnType<ActionCreators>;
