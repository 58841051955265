import { NavItem, NavLink } from "reactstrap";
// import classnames from "classnames";

interface IReactstrapTabNavLink {
  activeTab: string;
  tabContent: string;
  toggle(tab: string): void;
}

export default function ReactstrapTabNavLink({
  activeTab = "WORKER",
  tabContent,
  toggle,
}: IReactstrapTabNavLink) {
  return (
    <NavItem
      style={{
        width: "max-content",
        transform: activeTab === tabContent ? "scale(1.05)" : "scale(1)",
        transition: "all 0.3s",
      }}
    >
      <NavLink
        onClick={() => {
          toggle(tabContent);
        }}
        style={{
          height: "40px",
          textAlign: "center",
        }}
      >
        {tabContent}
      </NavLink>
    </NavItem>
  );
}
