export const formatDateAndTime = (date: string) => {
  return (
    new Date(date).toLocaleDateString('en-CA') +
    ' ' +
    new Date(date).toLocaleTimeString('en-CA', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    })
  );
};
export const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString('en-CA');
};
export const formatTime = (date: string) => {
  return new Date(date).toLocaleTimeString('en-CA', {
    hour: '2-digit',
    minute: '2-digit',
  });
};
export const getDateFormat = (date: string) => {
  const tempArr = date.split('/');
  const dateString = tempArr[1] + '月' + tempArr[0] + '日';
  return dateString;
};
export const setDateToStart = (date: string) => new Date(new Date(date).setHours(0, 0, 0, 0));

export const timeFormatter = new Intl.DateTimeFormat('it-IT', {
  hour: '2-digit',
  minute: '2-digit',
});

export const formatDateAndTimeWithUnknown = (date: string) => {
  if (
    formatTime(date) === '12:00 a.m.' ||
    formatTime(date) === '12:00 AM' ||
    formatTime(date) === '00:00' ||
    formatTime(date) === '24:00'
  ) {
    return formatDate(date);
  } else {
    return (
      new Date(date).toLocaleDateString('en-CA') +
      ' ' +
      new Date(date).toLocaleTimeString('en-CA', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
      })
    );
  }
};

export const isWithin1Week = (date: string) => {
  return new Date().valueOf() - new Date(date).valueOf() < 6.048e8;
};
