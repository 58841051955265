import { LoadingActions } from './action';
import { LoadingState } from './state';

export const initialState: LoadingState = {
  isLoading: false,
  changesMade: false,
  activeTab: 0,
  messageModal: {
    isOpen: false,
    content: '',
  },
  pathnameRoot: 'order',
};

export default function loadingReducer(state: LoadingState = initialState, action: LoadingActions) {
  switch (action.type) {
    case '@@Loading/setIsLoading':
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case '@@Loading/setChangesMade':
      return {
        ...state,
        changesMade: action.changesMade,
      };
    case '@@Loading/setActiveTab':
      return {
        ...state,
        activeTab: action.activeTab,
      };
    case '@@Loading/setPathnameRoot':
      return {
        ...state,
        pathnameRoot: action.root,
      };
    case '@@Loading/setMessageModal':
      return {
        ...state,
        messageModal: {
          isOpen: action.data.isOpen,
          content: action.data.content ?? initialState.messageModal.content,
        },
      };

    default:
      return state;
  }
}
