import axios from "axios";
import { StarIcon } from "components/IconsOnly";
import "css/GuestSatisfactory.css";
import { formatDateAndTime } from "helpers/date";
import { A_SURVEY } from "models/authorities";
import { IOrderSatisfactory } from "models/responseModels";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { default as ReactSignatureCanvas, default as SignaturePad } from "react-signature-canvas";
import { Button, Col, Container, Modal, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setIsLoading, setMessageModal } from "redux/Loading/action";
import { IRootState } from "store";

interface IImagesRecord {
  header: string;
}

const MAX_CANVAS_WIDTH = 560;
const satisfactoryText = ["極不滿意", "　", "一般", "　", "非常滿意"];

export default function GuestSatisfactoryPage({ header }: IImagesRecord) {
  const defaultOrderSatisfactory = {
    communication: 0,
    createdAt: "",
    id: 0,
    safety: 0,
    serviceQuality: 0,
    technical: 0,
    updatedAt: "",
  };
  const [orderSatisfactory, setOrderSatisfactory] =
    useState<IOrderSatisfactory>(defaultOrderSatisfactory);
  const [isNew, setIsNew] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [signatureData, setSignatureData] = useState("");
  const [canvasWidth, setCanvasWidth] = useState(
    window.innerWidth > 592 ? MAX_CANVAS_WIDTH : window.innerWidth + 32
  );
  const [isMissingSignature, setIsMissingSignature] = useState(true);
  const pathname = useSelector((state: IRootState) => state.router.location.pathname);
  const pathnameSplit = pathname.split("/");
  const orderId = pathnameSplit[pathnameSplit.length - 1];
  const signatureRef = useRef<ReactSignatureCanvas>(null);
  const userRole = useSelector((state: IRootState) => state.auth.role);

  const dispatch = useDispatch();

  const fetchGuestSatisfactory = async () => {
    try {
      const res = await axios.get(`order-survey/${orderId}`);
      const result = await res.data;
      if (!result.data) {
        setOrderSatisfactory(defaultOrderSatisfactory);
        setIsNew(true);
      } else {
        setOrderSatisfactory(result.data);
        setIsNew(false);
        const res2 = await axios.get(`/order-signature/${orderId}`);
        const result2 = await res2.data;
        setSignatureData(result2.signature);
      }
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  useEffect(() => {
    fetchGuestSatisfactory();
    if (window.innerWidth > MAX_CANVAS_WIDTH + 32) {
      setCanvasWidth(MAX_CANVAS_WIDTH);
    } else {
      setCanvasWidth(window.innerWidth - 32);
    }
    return () => {
      setOrderSatisfactory(defaultOrderSatisfactory);
    };
  }, []);

  useEffect(() => {
    if (!isNew) {
      fetchGuestSatisfactory();
    }
  }, [isNew]);

  const onSubmit = async () => {
    dispatch(setIsLoading(true));
    try {
      await axios.post(`/order-survey/${orderId}`, {
        survey: {
          technical: orderSatisfactory.technical ?? 0,
          communication: orderSatisfactory.communication ?? 0,
          safety: orderSatisfactory.safety ?? 0,
          serviceQuality: orderSatisfactory.serviceQuality ?? 0,
        },
      });
      if (!isMissingSignature) {
        await axios.post(`/order-signature/${orderId}`, {
          signature: signatureRef?.current?.toDataURL(),
        });
      }
      setIsMissingSignature(true);
      setConfirmModal(false);
      setIsNew(false);
      setTimeout(() => {
        dispatch(setIsLoading(false));
        dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
        setConfirmModal(false);
      }, 800);
    } catch (error) {
      dispatch(handleAxiosError(error));
      setTimeout(() => {
        dispatch(setIsLoading(false));
        dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
        setConfirmModal(false);
      }, 800);
    }
  };

  const clearSignature = () => {
    signatureRef.current?.clear();
    setIsMissingSignature(true);
  };

  useEffect(() => {
    if (!signatureRef.current) return;
    setIsMissingSignature(signatureRef.current.isEmpty());
  }, [signatureRef]);

  return (
    <div
      className="px-4 flex-column-start"
      style={{
        margin: 0,
        minWidth: "100vw",
        maxWidth: "100vw",
        maxHeight: "calc(100vh - 115px)",
        overflowY: "auto",
      }}
    >
      {!isNew ? (
        <div className="pb-4 flex-column-start full-width" style={{ position: "relative" }}>
          <h6 className="mt-4 full-width" style={{ color: "#AAA" }}>{`已評分及簽名`}</h6>
          <StarCard title={"服務質量"} score={orderSatisfactory.serviceQuality} />
          <StarCard title={"技術"} score={orderSatisfactory.technical} />
          <StarCard title={"溝通"} score={orderSatisfactory.communication} />
          <StarCard title={"安全"} score={orderSatisfactory.safety} />
          <div className="mt-4 flex-column-start full-width">
            <h5>客戶簽署</h5>
            <div
              className="flex-center full-width relative"
              style={{
                border: "solid 1px #0005",
                width: canvasWidth,
                minHeight: (canvasWidth * 9) / 16,
              }}
            >
              <div
                className="absolute"
                style={{ background: "white", right: 0, top: 0, padding: "0 16px" }}
              >
                {formatDateAndTime(orderSatisfactory.createdAt)}
              </div>
              <img
                src={signatureData}
                style={{ maxWidth: canvasWidth, height: (canvasWidth * 9) / 16 }}
                alt="Signature"
              />
            </div>
            <div style={{ minHeight: "5em", width: "100vw" }} />
          </div>
        </div>
      ) : A_SURVEY.includes(userRole) ? (
        <div className="flex-column-start full-width" style={{ position: "relative" }}>
          <h6 className="mt-4 full-width" style={{ color: "#AAA" }}>
            {`請客戶評分及簽名`}
            <br />
            {`* 請注意：評分及簽名儲存後將不能再改動`}
          </h6>
          <EditableStarCard
            title={"服務質量"}
            identity={"serviceQuality"}
            score={0}
            satisfactory={orderSatisfactory}
            setSatisfactory={setOrderSatisfactory}
          />

          <EditableStarCard
            identity={"technical"}
            title={"技術"}
            score={0}
            satisfactory={orderSatisfactory}
            setSatisfactory={setOrderSatisfactory}
          />
          <EditableStarCard
            identity={"communication"}
            title={"溝通"}
            score={0}
            satisfactory={orderSatisfactory}
            setSatisfactory={setOrderSatisfactory}
          />

          <EditableStarCard
            identity={"safety"}
            title={"安全"}
            score={0}
            satisfactory={orderSatisfactory}
            setSatisfactory={setOrderSatisfactory}
          />
          <div
            className="my-3 full-width flex-column-center"
            style={{ minHeight: "fit-content", paddingBottom: "10em" }}
          >
            <div>
              <div className="my-2 flex-row-between full-width">
                <Col />
                <Col>
                  <h5 style={{ marginBottom: 0 }}>客戶簽署</h5>
                </Col>
                <Col>
                  <Button className="full-width" onClick={clearSignature}>
                    清除
                  </Button>
                </Col>
              </div>
              <div style={{ border: "solid 1px #0005" }}>
                <SignaturePad
                  ref={signatureRef}
                  penColor="black"
                  minWidth={0.5}
                  maxWidth={2}
                  onEnd={() => setIsMissingSignature(false)}
                  canvasProps={{ width: canvasWidth - 32, height: (canvasWidth * 9) / 16 }}
                />
              </div>
            </div>
            <div className="my-4 flex-center full-width" style={{ minHeight: "48px" }}>
              <Button
                disabled={isMissingSignature}
                className="my-1 w-50"
                color="primary"
                onClick={() => {
                  setConfirmModal(true);
                }}
              >
                儲存
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex-column-start full-width" style={{ position: "relative" }}>
          <h6 className="mt-4 full-width" style={{ color: "#AAA" }}>
            {`請通知前線員工 要求客戶評分及簽名`}
          </h6>
        </div>
      )}
      <AreYouSureModal
        isOpen={confirmModal}
        onCancel={() => setConfirmModal(false)}
        onConfirm={onSubmit}
      />
    </div>
  );
}

interface StarCardProps {
  title: string;
  score: number;
}

const StarCard = (props: StarCardProps) => {
  const { title, score } = props;

  return (
    <div
      className="my-1 d-flex full-width justify-content-center"
      style={{ maxWidth: "480px", minHeight: "160px" }}
    >
      <div className="my-2 py-3 flex-column-center starContainer full-width">
        <h5 className="px-2 flex-row-start full-width">{title}</h5>
        <div className="py-2 flex-center w-95">
          {Array(5)
            .fill(0)
            .map((item, idx) => {
              return (
                <div
                  key={idx + 10}
                  className="mx-1 flex-column-center"
                  style={{
                    width: "60px",
                    whiteSpace: "nowrap",
                    lineHeight: "2em",
                    justifyContent: "space-around",
                  }}
                >
                  <StarIcon
                    position={`${title}_${idx}`}
                    offset={Math.max(0, Math.min(1, score - idx))}
                  />
                  {satisfactoryText[idx]}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

type Identity = "communication" | "safety" | "serviceQuality" | "technical";

interface EditableStarCardProps {
  identity: Identity;
  satisfactory: IOrderSatisfactory;
  setSatisfactory: React.Dispatch<React.SetStateAction<IOrderSatisfactory>>;
}

const EditableStarCard = (props: StarCardProps & EditableStarCardProps) => {
  const { title, satisfactory, setSatisfactory, identity } = props;

  return (
    <div
      className="my-1 d-flex full-width justify-content-center"
      style={{ maxWidth: "480px", minHeight: "160px" }}
    >
      <div className="flex-column-start full-width">
        <div className="my-2 py-3 flex-column-center starContainer">
          <h5 className=" px-2 flex-row-start full-width">{title}</h5>
          <div className="py-2 flex-center" style={{ alignItems: "flex-start", maxWidth: "100%" }}>
            {Array(5)
              .fill(0)
              .map((item, idx) => {
                return (
                  <div
                    key={idx + 10}
                    className="mx-1 flex-column-center"
                    style={{ width: "60px", paddingTop: "3%" }}
                    onClick={() => setSatisfactory({ ...satisfactory, [identity]: idx + 1 })}
                  >
                    <StarIcon
                      position={`${title}_${idx}`}
                      offset={Math.max(0, Math.min(1, satisfactory[identity] - idx))}
                    />
                    {satisfactoryText[idx]}
                  </div>
                );
              })}
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

interface ModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const AreYouSureModal = ({ isOpen, onConfirm, onCancel }: ModalProps) => {
  return (
    <Modal centered isOpen={isOpen}>
      <Container className="py-3">
        <Row className="my-2 flex-center">
          <h4 className="flex-center">確認上傳？</h4>
        </Row>
        <Row className="my-2 mt-3 flex-row-around">
          <Col className="flex-center">
            <Button className="w-80" onClick={onCancel}>
              取消
            </Button>
          </Col>
          <Col className="flex-center">
            <Button color="primary" className="w-80" onClick={onConfirm}>
              確認
            </Button>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};
