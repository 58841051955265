import { OrderByIdActions } from './action';
import { OrderByIdState } from './state';

export const initialState: OrderByIdState = {
  orderById: {
    // assignedToUserId: 0,
    // assignedToUserName: '',
    clientAddress: '',
    clientContactPerson: '',
    clientEmail: '',
    clientId: 0,
    clientName: '',
    clientTel: '',
    completeDate: null,
    district: 'CENTRAL_AND_WESTERN',
    division: 'MAINTENANCE',
    id: 0,
    informedBy: '',
    invoiceIssued: false,
    issuedByUserId: false,
    issuedByUserName: '',
    orderNumber: '',
    paymentMade: null,
    priority: 'NOT_URGENT',
    progressStatus: 'NEW',
    serviceReport: '',
    serviceSubject: '',
    serviceType: 'M1',
    serviceTypeOthers: '',
    startDate: null,
    surveyCompleted: false,
    totalPrice: 0,
    updatedAt: '',
    acjNo: null,
    billToClient: null,
    personInCharge: null,
    customerCode: null,
    contractAmount: null,
    expectedStartDate: null,
    expectedCompleteDate: null,
    externalDocumentNo: null,
    salesQuoteNo: null,
    createdAt: '',
    remarks: '',
    cancelReason: '',
  },
  pagination: {
    currentPage: 1,
    from: 1,
    lastPage: 1,
    perPage: 10,
    to: 1,
    total: 1,
  },
  changesMade: false,
  newOrderData: {
    orderNumber: '',
    clientId: 0,
    informedBy: '',
    serviceType: '',
    priority: 'NOT_URGENT',
    totalPrice: 0,
    serviceTypeOthers: '',
    name: '',
    division: '',
    address: '',
    contactPerson: '',
    contactNumber: '',
    email: '',
    acjNo: null,
    billToClient: null,
    personInCharge: null,
    customerCode: null,
    contractAmount: 0,
    expectedStartDate: null,
    expectedCompleteDate: null,
    externalDocumentNo: null,
    salesQuoteNo: null,
    serviceReport: '',
    serviceSubject: '',
    staffIds: [],
    // assignedToUserId: null,
    // assignedToUserName: null,
  },
  assignWorkers: [],
  image: {
    imageDescription: '',
    imageModalOpen: false,
  },
};

export default function orderByIdReducer(
  state: OrderByIdState = initialState,
  action: OrderByIdActions
) {
  switch (action.type) {
    case '@@OrderById/setOrderByIdData':
      return {
        ...state,
        orderById: action.data,
      };
    case '@@OrderById/clearData':
      return {
        ...state,
        orderById: initialState.orderById,
      };
    case '@@OrderById/handleClientChange':
      return {
        ...state,
        orderById: {
          ...state.orderById,
          clientId: action.data.id ?? state.orderById.clientId,
          clientName: action.data.name ?? ' - ',
          clientDistrict: action.data.district ?? ' - ',
          clientAddress: action.data.address ?? ' - ',
          clientContactPerson: action.data.contactPerson ?? ' - ',
          clientTel: action.data.contactNumber ?? ' - ',
          clientEmail: action.data.email ?? ' - ',
        },
      };
    case '@@NewServiceOrder/setNewOrderData':
      return {
        ...state,
        newOrderData: action.data,
      };
    case '@@NewServiceOrder/clearNewOrderData':
      return {
        ...state,
        newOrderData: {
          ...initialState.newOrderData,
        },
      };

    case '@@OrderById/assignWorkerToList':
      const assignWorkersClone = state.assignWorkers.slice();
      if (!assignWorkersClone.includes(action.id)) {
        assignWorkersClone.push(action.id);
      }
      return {
        ...state,
        assignWorkers: assignWorkersClone,
      };
    case '@@OrderById/removeWorkerFromList':
      const tempArray = state.assignWorkers.slice();
      tempArray.splice(tempArray.indexOf(action.id), 1);
      return {
        ...state,
        assignWorkers: tempArray,
      };
    case '@@OrderById/resetWorkerList':
      return {
        ...state,
        assignWorkers: initialState.assignWorkers,
      };

    case '@@OrderById/setImageModalOpen':
      return {
        ...state,
        image: {
          ...state.image,
          imageModalOpen: action.isOpen,
        },
      };
    case '@@OrderById/setImageModalDescription':
      return {
        ...state,
        image: {
          ...state.image,
          imageDescription: action.description,
        },
      };
    case '@@OrderById/setServiceReport':
      return {
        ...state,
        orderById: {
          ...state.orderById,
          serviceReport: action.data.serviceReport ?? state.orderById.serviceReport,
          serviceSubject: action.data.serviceSubject ?? state.orderById.serviceSubject,
        },
      };
    //pagination
    case '@@Pagination/setWorkPagination':
      return {
        ...state,
        pagination: action.data,
      };
    case '@@Pagination/setWorkCurrentPage':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: action.currentPage,
        },
      };
    case '@@Pagination/resetWorkPagination':
      return {
        ...state,
        ...initialState.pagination,
      };
    case '@@Pagination/setWorkChangesMade':
      return {
        ...state,
        changesMade: action.changesMade,
      };
    //end of pagination

    default:
      return state;
  }
}
