import axios from "axios";
import { CloseIcon, OpenListIcon } from "components/IconsOnly";
import { formatDateAndTime } from "helpers/date";
import { IDocumentTemplate } from "models/responseModels";
import { DIVISIONS } from "models/variables";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Modal, Row, Spinner } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { resetDocumentData, setChangesMade, setDocumentData } from "redux/Document/action";
import { DocumentData } from "redux/Document/state";
import { setMessageModal } from "redux/Loading/action";
import {
  setNewDocumentModalOpen,
  setSelectedDocument,
  setSelectedDocumentModalOpen,
} from "redux/ServiceDocument/action";
import { IRootState } from "store";
import SortableTableHeaders from "./SortableTableHeaders";

export interface IDocumentList {
  header: string;
  isSelectionMode?: boolean;
  searchText?: string;
  setSearchText?: (args0: string) => void;
  handleSearch?: () => void;
  sortByDirection?: { sortType: string; isDesc: boolean };
  setSortByDirection?: React.Dispatch<
    React.SetStateAction<{
      sortType: string;
      isDesc: boolean;
    }>
  >;
}
interface IDocumentTemplateList {
  docTypeId: number;
  templateId: number;
  templateName: string;
}

export interface PostContent {
  docFieldId: number;
  fieldName: string;
  contentId?: number;
  content: string;
}
export default function DocumentList(props: IDocumentList) {
  const {
    // header,
    isSelectionMode = false,
    // searchText,
    // setSearchText,
    // handleSearch,
    sortByDirection,
    setSortByDirection,
  } = props;
  // const [modal, setModal] = useState(false);

  const [expandList, setExpandList] = useState(-1);
  const [postContent, setPostContent] = useState<{
    docName: string;
    docTypeId: number;
    fields: PostContent[];
  }>({
    docName: "",
    docTypeId: -1,
    fields: [],
  });
  const [templateDetails, setTemplateDetails] = useState<IDocumentTemplateList[]>([]);
  const [saveDocumentModalOpen, setSaveDocumentModalOpen] = useState(false);

  const sortableHeaders = isSelectionMode
    ? [
        // { headerText: "文件類型編號", headerKey: "formNo" },
        { headerText: "文件類型名稱", headerKey: "name" },
        { headerText: "註解", headerKey: "description" },
        { headerText: "部門", headerKey: "division" },
        { headerText: "更新日期", headerKey: "updatedAt" },
      ]
    : [
        { headerText: "動作", headerKey: "" },
        { headerText: "文件類型編號", headerKey: "formNo" },
        { headerText: "文件類型名稱", headerKey: "name" },
        { headerText: "註解", headerKey: "description" },
        { headerText: "部門", headerKey: "division" },
        { headerText: "更新日期", headerKey: "updatedAt" },
      ];

  const isLoading = useSelector((state: IRootState) => state.loading.isLoading);
  const data = useSelector((state: IRootState) => state.documents.documentDataArray);
  // const documentData = useSelector((state: IRootState) => state.documents.documentData);
  // const pagination = useSelector((state: IRootState) => state.documents.pagination);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSelect = (item: DocumentData) => {
    dispatch(setDocumentData(item));
    if (!isSelectionMode) {
      history.push(`/admin/document/${item.id}`);
    } else {
      dispatch(setSelectedDocumentModalOpen(true, "docType"));
    }
  };

  const handleSelectTemplate = (item: any) => {
    dispatch(setSelectedDocument(item));
    dispatch(setSelectedDocumentModalOpen(true, "template"));
  };

  useEffect(() => {
    dispatch(resetDocumentData());
    setExpandList(-1);
  }, [dispatch]);

  // useEffect(() => {
  //   const fetchDocumentFields = async () => {
  //     try {
  //       let res: AxiosResponse;
  //       if (isSelectionMode) {
  //         res = await axios.get(`/doc-type-fields/${documentData.id}`);
  //       } else {
  //         res = await axios.get(`/doc-type-fields/${docTypeId}`);
  //       }
  //       const result = res.data.data;

  //       if (isSelectionMode) {
  //         setPostContent({
  //           docName: "",
  //           docTypeId: documentData.id,
  //           fields: result.map((i: any) => {
  //             return { docFieldId: i.id, fieldName: i.fieldName, content: "" };
  //           }),
  //         });
  //       } else {
  //         setAllFields(result);
  //       }
  //     } catch (error) {
  //       dispatch(handleAxiosError(error));
  //     }
  //   };
  //   fetchDocumentFields();
  //   return () => {
  //     setAllFields([]);
  //   };
  // }, []);

  const fetchThisDocumentTemplates = async (docTypeId: number, idx: number) => {
    if (expandList !== idx) {
      try {
        const url = new URL(`${process.env.REACT_APP_API_PATH}/doc`);
        url.searchParams.set("docTypeId", docTypeId.toString());
        url.searchParams.set("template", "true");
        const res = await axios.get(url.toString());
        const result = res.data;
        setTemplateDetails(
          result.data.map((i: IDocumentTemplate) => {
            return { docTypeId: i.docTypeId, templateId: i.id, templateName: i.name };
          })
        );
        setExpandList(idx);
      } catch (error) {
        dispatch(handleAxiosError(error));
      }
    } else if (expandList === idx) {
      setExpandList(-1);
    }
  };
  const handleControlSort = (sortType: string) => {
    setExpandList(-1);
    if (sortByDirection && setSortByDirection) {
      if (sortByDirection.sortType === sortType) {
        setSortByDirection({ sortType, isDesc: !sortByDirection.isDesc });
      } else {
        setSortByDirection({ sortType, isDesc: true });
      }
    }
  };
  const postDocumentToOrder = async () => {
    const pathname = window.location.pathname.split("/");
    const orderId = pathname[pathname.length - 1];
    try {
      const res = await axios.post(`/doc/${orderId}`, {
        docInfo: {
          name: postContent.docName,
          docTypeId: postContent.docTypeId,
        },
      });
      const documentId = await res.data.id;
      await axios.post(`/doc-content/${documentId}`, {
        docFields: postContent.fields,
      });
      dispatch(setChangesMade(true));
      // setSaveDocumentModalOpen(false);
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
      // handleExit();
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };

  return (
    <div className="p-1 relative" style={{ overflowX: "hidden" }}>
      {!isSelectionMode ? (
        <></>
      ) : (
        <Row className="m-3">
          {/* <FormHeader offset={false} header={header} /> */}
          <div
            className="flex-row-between pointer"
            style={{ position: "absolute", right: "0px", top: 0 }}
          >
            <div className="flex1" />
            <div>
              <h5 className="m-0 flex1">選擇文件</h5>
            </div>
            <div
              className="flex1 flex-row-end"
              onClick={() => dispatch(setNewDocumentModalOpen(false))}
            >
              <CloseIcon />
            </div>
          </div>
        </Row>
      )}
      {/* <ActionRow
        lastPage={pagination.lastPage}
        addItem="文件類型"
        currentPage={pagination.currentPage}
        setCurrentPageFunction={setDocumentCurrentPage}
        isSelectionMode={isSelectionMode}
        searchText={searchText}
        setSearchText={setSearchText}
        handleSearch={handleSearch}
        placeholderText={placeholderText}
      /> */}
      {!data.length ? (
        <div className="my-2 flex-center full-width disableText">沒有文件</div>
      ) : (
        <div className="mt-4 flex-row-start full-width" style={{ overflowX: "auto" }}>
          <div className={isSelectionMode ? "p-2 px-4 mx-1" : "p-2"}>
            <SortableTableHeaders
              headers={sortableHeaders}
              sortControl={handleControlSort}
              currentSorting={sortByDirection}
            />
            <div className=" relative">
              {isLoading ? (
                <Spinner style={{ width: "3rem", height: "3rem" }}>{""}</Spinner>
              ) : (
                data.map((item, idx) => {
                  return (
                    <div key={item.id}>
                      <div className="flex-center tableRow">
                        {isSelectionMode && (
                          <div
                            style={{ position: "absolute", left: "-24px" }}
                            onClick={() => fetchThisDocumentTemplates(item.id, idx)}
                          >
                            <OpenListIcon isInverted={expandList === idx} />
                          </div>
                        )}
                        {/* {!isSelectionMode && (
                          <div className="flex-row-around tdActionContainer">
                            <div
                              className="flex-center tdIconContainer full-height"
                              onClick={() => {
                                setModal(!modal);
                                setDeleteTarget({ name: item.name, id: item.id });
                              }}
                            >
                              <DeleteIcon />
                            </div>
                          </div>
                        )} */}
                        {/* <div className="flex-center tdItem" onClick={() => handleSelect(item)}>
                          {item.formNo || "-"}
                        </div> */}
                        <div className="tdMainItem" onClick={() => handleSelect(item)}>
                          {item.name || "-"}
                        </div>
                        <div className="tdLongItem" onClick={() => handleSelect(item)}>
                          {item.description || "-"}
                        </div>
                        <div className="flex-center tdDistrict" onClick={() => handleSelect(item)}>
                          {(item.division && DIVISIONS[item.division]) || "-"}
                        </div>
                        <div className="flex-center tdDateItem" onClick={() => handleSelect(item)}>
                          {formatDateAndTime(item.updatedAt) || "-"}
                        </div>
                      </div>
                      {isSelectionMode &&
                        expandList === idx &&
                        (templateDetails.length > 0 ? (
                          templateDetails.map((item) => {
                            return (
                              <div
                                key={item.templateId}
                                className="flex-center templateTableRow"
                                onClick={() => handleSelectTemplate(item)}
                              >
                                <div className="flex-center templateTdItem">
                                  {item.templateName}
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className={`flex-center emptyContentTemplateTableRow`}>
                            <div className="flex-center templateTdItem">{"沒有範本"}</div>
                          </div>
                        ))}
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      )}
      {isSelectionMode && saveDocumentModalOpen && (
        <Modal size="md" isOpen={saveDocumentModalOpen}>
          <Container
            size="lg"
            className="p-4 d-flex flex-column justify-content-around"
            style={{ minHeight: "200px" }}
          >
            <Row>
              <Col className="flex-center">
                <h5>儲存文件</h5>
              </Col>
            </Row>
            <Row>
              <Col md={1} style={{ width: "max-content" }}>
                文件名稱
              </Col>
              <Col>
                <input
                  className="inputStyle"
                  value={postContent.docName}
                  onChange={(e) => {
                    setPostContent({ ...postContent, docName: e.target.value });
                  }}
                />
              </Col>
            </Row>
            <Row className="flex-center mt-3">
              <Col md={6} className="flex-center">
                <Button style={{ width: "100%" }} onClick={() => setSaveDocumentModalOpen(false)}>
                  取消
                </Button>
              </Col>
              <Col md={6} className="flex-center">
                <Button color="primary" style={{ width: "100%" }} onClick={postDocumentToOrder}>
                  儲存
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal>
      )}
    </div>
  );
}
