import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Container, Row, Col, Button } from "reactstrap";
import { setMessageModal } from "redux/Loading/action";
import { IRootState } from "store";

function SaveSuccessModal() {
  const messageModal = useSelector((state: IRootState) => state.loading.messageModal);
  const dispatch = useDispatch();
  return (
    <Modal centered isOpen={messageModal.isOpen}>
      <Container className="p-2">
        <Row className="my-3 flex-column-center">
          <Col style={{ textAlign: "center" }}>
            <h6>{messageModal.content}</h6>
          </Col>
          <Col className="mt-3 flex-center">
            <Button
              style={{ width: "50%" }}
              onClick={() => {
                dispatch(setMessageModal({ isOpen: false }));
              }}
            >
              OK
            </Button>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}

export default SaveSuccessModal;
