export const videoFormats = [
  '3gp',
  '3g2',
  '3gpp',
  '3gpp2',
  'amv',
  'asf',
  'avi',
  'drc',
  'f4v',
  'f4p',
  'f4a',
  'f4b',
  'flv',
  'gif',
  'gifv',
  'M2TS',
  'm4p',
  'm4v',
  'mpg',
  'mp2',
  'mpeg',
  'mpe',
  'mpv',
  'mkv',
  'mxf',
  'mp4',
  'MP4',
  'mov',
  'MTS',
  'mng',
  'nsv',
  'ogv',
  'ogg',
  'rm',
  'rmvb',
  'roq',
  'svi',
  'TS',
  'viv',
  'vob',
  'quicktime',
  'qt',
  'webm',
  'wmv',
  'yuv',
];

export const unplayableFormats = ['mov', 'quicktime'];
