export const midLevelUp = ['SUPERVISOR', 'FOREMEN', 'SUPER_ADMIN', 'ADMIN'];
export const highLevel = ['SUPER_ADMIN', 'ADMIN'];
export const midLevel = ['SUPERVISOR', 'FOREMEN'];

export const A_ADD_ORDER = ['SUPER_ADMIN', 'SUPERVISOR', 'FOREMEN'];
export const A_DELETE_ORDER = ['SUPER_ADMIN', 'SUPERVISOR'];
export const A_ASSIGN_WORKER = ['SUPER_ADMIN', 'SUPERVISOR', 'FOREMEN'];
export const A_DELETE_MEDIA = ['SUPER_ADMIN', 'SUPERVISOR', 'FOREMEN'];
export const A_SURVEY = ['SUPER_ADMIN', 'SUPERVISOR', 'FOREMEN', 'WORKER']; //mobile only
export const A_CREATE_NEW_TEMPLATE = ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'FOREMEN'];
export const A_ADD_COMPANY = ['SUPER_ADMIN', 'SUPERVISOR'];
export const A_ADD_WORKER = ['SUPER_ADMIN'];
export const A_ADD_CLIENT = ['SUPER_ADMIN', 'SUPERVISOR'];
export const A_MANAGE_DISTRICT = ['SUPER_ADMIN'];
//added Dec23 16:20
export const A_CHG_TO_COMPLETED = ['SUPER_ADMIN', 'SUPERVISOR', 'FOREMEN'];
export const A_VIEW_DOC_TYPE = ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'FOREMEN'];
export const A_VIEW_COMPANY = ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'FOREMEN'];
export const A_VIEW_WORKER = ['SUPER_ADMIN', 'SUPERVISOR', 'FOREMEN'];
export const A_VIEW_CLIENT = ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'FOREMEN', 'WORKER'];
export const A_DASHBOARD = ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'FOREMEN'];
